import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';

import { ImageZoom } from '../ImageZoom';

const { Meta } = Card;

export const ProductCard = ({ image, title, sku }) => {
  return (
    <Card
      cover={<ImageZoom image={image} />}
      actions={[
        <SettingOutlined key='setting' />,
        <EditOutlined key='edit' />,
        <EllipsisOutlined key='ellipsis' />,
      ]}
    >
      <Meta title={sku} description={title} />
    </Card>
  );
};
