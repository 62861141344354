import { Carousel, Col, Row, Space, Spin } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { Link, useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { RightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { Container, Hero, HighlightButton } from '../../components';
import { pageContentsActions } from '../../store';
import { FeaturedProduct } from './components/FeaturedProduct';
import {
  HeroBackdrop,
  HeroContainer,
  HeroSubTitle,
  HeroTitle,
  HomeSectionTitle,
  Testimonial,
  TestimonialAuthor,
} from './styled';
import './styles.less';

export const Home = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // App State
  const content = useSelector(
    (state) => state.pageContents.content?.landingPage
  );
  const [queryParams] = useQueryParams({
    version: StringParam,
  });

  // local state
  const [loading, setLoading] = useState(!content);

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content)
      dispatch(
        pageContentsActions.getPageContent({
          ...queryParams,
          dataKey: 'landingPage',
        })
      ).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  const testimonialList =
    content?.testimonials &&
    content?.testimonials.map((item, index) => {
      return (
        <React.Fragment key={'testimonial-' + index}>
          <Row>
            <Col>
              <Testimonial>{item.quote}</Testimonial>
              <TestimonialAuthor>{item.author}</TestimonialAuthor>
            </Col>
          </Row>
        </React.Fragment>
      );
    });

  const carouselSettings = {
    dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    autoplaySpeed: 6000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true,
    arrows: true,
    autoplay: true,
    pauseOnDotsHover: true,
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: 'home.title' })}</title>
      </Helmet>
      <Spin
        spinning={loading}
        size='large'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
      {!loading && content && (
        <React.Fragment>
          <Container className='home' style={{ paddingTop: 0 }}>
            <Hero
              title={content?.hero1Title}
              subText={content?.hero1SubText}
              body={content?.hero1Body}
              image={content?.hero1Image}
              hyperlinkText={content?.hero1HyperlinkText}
              hyperlinkUrl={content?.hero1HyperlinkUrl}
              buttonText={content?.hero1ButtonText}
              buttonUrl={content?.hero1ButtonUrl}
            />
          </Container>
          <Container className='home'>
            <Fade>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <FeaturedProduct
                    title={content?.featured1Title}
                    subText={content?.featured1SubText}
                    image={content?.featured1Image}
                    hyperlinkText={content?.featured1HyperlinkText}
                    hyperlinkUrl={content?.featured1HyperlinkUrl}
                    priceSpiderSku={content?.featured1PriceSpiderSku}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <FeaturedProduct
                    title={content?.featured2Title}
                    subText={content?.featured2SubText}
                    image={content?.featured2Image}
                    hyperlinkText={content?.featured2HyperlinkText}
                    hyperlinkUrl={content?.featured2HyperlinkUrl}
                    priceSpiderSku={content?.featured2PriceSpiderSku}
                  />
                </Col>
              </Row>
            </Fade>
          </Container>
          <Container className='home' style={{ paddingTop: 0 }}>
            <HeroContainer align='middle' image={content?.hero2Image}>
              <Col xs={24} sm={24} md={14} lg={12} xl={10}>
                <Fade>
                  <HeroBackdrop style={{ position: 'relative' }}>
                    <Fade top delay={200}>
                      <HeroTitle>{content?.hero2Title}</HeroTitle>
                    </Fade>
                    <Fade delay={350}>
                      {content?.hero2SubText && (
                        <HeroSubTitle>{content?.hero2SubText}</HeroSubTitle>
                      )}
                      <ReactMarkdown className={'markdown-light'}>
                        {content?.hero2Body}
                      </ReactMarkdown>
                      <React.Fragment>
                        <Space direction='vertical'>
                          {content?.hero2HyperlinkText &&
                            content?.hero2HyperlinkUrl && (
                              <Link
                                to={content?.hero2HyperlinkUrl}
                                style={{ color: 'white' }}
                              >
                                {content?.hero2HyperlinkText} <RightOutlined />
                              </Link>
                            )}
                          {content?.hero2ButtonText && content?.hero2ButtonUrl && (
                            <div style={{ margin: '24px 0' }}>
                              <HighlightButton
                                onClick={() =>
                                  history.push(content?.hero2ButtonUrl)
                                }
                              >
                                {content?.hero2ButtonText}
                              </HighlightButton>
                            </div>
                          )}
                        </Space>
                      </React.Fragment>
                    </Fade>
                  </HeroBackdrop>
                </Fade>
              </Col>
            </HeroContainer>
          </Container>
          <Container className='home'>
            <Fade>
              <div style={{ margin: '24px' }}>
                <HomeSectionTitle>
                  {intl.formatMessage({ id: 'home.testimonials' })}
                </HomeSectionTitle>
                <Carousel {...carouselSettings}>{testimonialList}</Carousel>
              </div>
            </Fade>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
