import axios from 'axios';

import { error, success } from './utils';

const createAxiosAction = ({
  actionPayload,
  startAction,
  method,
  url,
  body,
  contentType = 'application/json',
  queryString = {},
}) => (dispatch, getState) => {
  dispatch({ ...actionPayload, ...queryString, type: startAction });

  let queryStringToAppend =
    Object.keys(queryString).length > 0
      ? Object.keys(queryString)
          .map((key) => {
            if (queryString[key] && Array.isArray(queryString[key])) {
              return queryString[key].map((val) => `${key}=${val}`).join('&');
            } else if (queryString[key]) {
              return `${key}=${queryString[key]}`;
            } else return null;
          })
          .filter(Boolean)
          .join('&')
      : '';

  let config = {
    method,
    data: body,
    url: queryStringToAppend !== '' ? `${url}?${queryStringToAppend}` : url,
    headers: {
      'Content-Type': contentType,
    },
  };

  return axios(config)
    .then((payload) => {
      dispatch({
        ...actionPayload,
        ...queryString,
        type: success(startAction),
        payload: payload.data,
      });
      return payload;
    })
    .catch((payload) => {
      dispatch({ ...actionPayload, ...queryString, type: error(startAction) });
      return Promise.reject(payload);
    });
};

export default createAxiosAction;
