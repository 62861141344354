import { Col, Row } from 'antd';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import {
  FeaturedDetails,
  FeaturedImage,
  FeaturedProductWrapper,
  FeaturedTitle,
  FeaturedLink,
} from './styled';

export const FeaturedItem = ({
  title,
  subText,
  image,
  hyperlinkText,
  hyperlinkUrl,
  featureCount,
}) => {
  return (
    <Col
      justify='center'
      xs={{ span: 24 }}
      xl={{ span: 24 / featureCount }}
      style={{ minHeight: '250px' }}
    >
      <FeaturedProductWrapper
        to={'/' + hyperlinkUrl}
        aria-label={'CategoryLink:' + hyperlinkUrl}
      >
        <Row style={{ margin: '10px' }}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FeaturedImage src={image} />
          </Col>
          <Col
            flex='1'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FeaturedDetails>
              <FeaturedTitle>{title}</FeaturedTitle>
              <p>{subText}</p>
              {hyperlinkText && hyperlinkUrl && (
                <FeaturedLink>
                  {hyperlinkText} <RightOutlined />
                </FeaturedLink>
              )}
            </FeaturedDetails>
          </Col>
        </Row>
      </FeaturedProductWrapper>
    </Col>
  );
};
