import { Form, Row, Typography } from 'antd';
import styled from 'styled-components';
import MET0368 from '../../../assets/images/MET0368.jpg';

const { Text } = Typography;

export const BackgroundImageContainer = styled.div`
  background-image: linear-gradient(rgb(30, 30, 30, 0.7), rgb(30, 30, 30, 0.7)),
    url(${MET0368});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: auto;
`;
export const NewsletterFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 40px;
`;

export const SignUpForm = styled(Form)`
  color: white;
  width: 45%;

  @media (max-width: 1400px) {
    width: 75%;
  }
`;

export const SignUpFormSubText = styled(Text)`
  text-align: center;
  color: white;
  font-size: 1.25em;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const SignUpFormRow = styled(Row)`
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  border: none;
  margin-right: 6px;

  .ant-form-item-label label {
    color: #fff;
  }
  .ant-input {
    height: 2.5em;
  }

  /* using important to override the specific widths that each form item has at larger breakpoints */
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const SignUpFormDisclaimerText = styled(Text)`
  text-align: center;
  color: white;
  width: 100%;
  margin-top: 1rem;
`;
