import React from 'react';

import { ImageWrapper, ProductCard } from './styled';

export const ProductImage = ({ imageUrl, maxHeight, highlight }) => {
  return (
    <ProductCard>
      <ImageWrapper highlight={highlight}>
        <img
          style={{ maxHeight: maxHeight, maxWidth: '100%' }}
          src={imageUrl}
          alt=''
        />
      </ImageWrapper>
    </ProductCard>
  );
};
