import React from 'react';
import { RightOutlined } from '@ant-design/icons';

import { FooterExternalLink, FooterLink, StyledFooterSection } from './styled';

export const FooterSection = ({ links, title, children }) => {
  return (
    <StyledFooterSection>
      <span>{title}</span>
      {children}
      {links && (
        <ul>
          {links.map((link, index) => (
            <li key={`support-link-${index}`}>
              {link.to ? (
                <FooterLink to={link.to}>
                  {link.title} <RightOutlined />
                </FooterLink>
              ) : (
                <FooterExternalLink
                  href={link.href}
                  target='_blank'
                  rel='noreferrer'
                >
                  {link.title} <RightOutlined />
                </FooterExternalLink>
              )}
            </li>
          ))}
        </ul>
      )}
    </StyledFooterSection>
  );
};
