import ReactGA from 'react-ga';

export function googleAnalyticsHelper(category, action, label) {
  if (typeof window !== 'undefined' && window.OnetrustActiveGroups) {
    let activeGroups = window.OnetrustActiveGroups.split(',').filter(Boolean);

    // C0002 OR 2 means the user has accepted Performance cookies
    if (activeGroups.includes('C0002') || activeGroups.includes('2')) {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
      });
    }
  }
}
