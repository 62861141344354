import { Row, Col, Layout, Typography, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ClaimDetailsTable } from './styled';

import { Container } from '../../../../components/Container';
import { useIntl } from 'react-intl';
import { emailSettingsActions } from '../../../../store';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export const WarrantyConfirmation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // App State
  const warrantyClaim = useSelector(
    (state) => state.warrantyClaim.warrantyClaim
  );

  const emailSettings = useSelector(
    (state) => state.emailSettings.emailSettings.settings
  );

  const emailSettingsLoading = useSelector(
    (state) => state.emailSettings.emailSettings.isLoading
  );

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!warrantyClaim) {
      history.push('/notfound');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warrantyClaim, dispatch]);

  useEffect(() => {
    if (!emailSettings) {
      dispatch(emailSettingsActions.getWarrantyClaimEmailSettings());
    }
  }, [emailSettings, dispatch]);

  const toolImages = warrantyClaim?.toolImageUrls?.map((item, index) => {
    return (
      <Col md={4} xs={12} key={index}>
        <a
          style={{ maxWidth: '100%' }}
          href={item}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            style={{ maxWidth: '100%', alignSelf: 'flex-start' }}
            src={item}
            alt={''}
          />
        </a>
      </Col>
    );
  });

  const reasonCodes = warrantyClaim?.reasonCodes?.map((item, index) => {
    return <React.Fragment>{index === 0 ? item : ', ' + item}</React.Fragment>;
  });

  const content = (
    <Content>
      <Title level={2}>{emailSettings?.emailHeader}</Title>
      <Title level={4}>{emailSettings?.emailSubHeader}</Title>
      <Paragraph>{emailSettings?.emailBody}</Paragraph>
      <Space direction='vertical' size={1}>
        <Paragraph>{emailSettings?.emailBodyLine2}</Paragraph>
        <Text>
          <a href='tel:1 (800) 987-1849'>1 (800) 987-1849</a>
        </Text>
        <Text style={{ marginTop: '10px' }}>
          <a href='mailto:warranty@stiletto.com'>warranty@stiletto.com</a>
        </Text>
        <Paragraph style={{ marginTop: '10px' }}>
          <Link to='/'>
            {intl.formatMessage({ id: 'general.stilettoWebsite' })}
          </Link>
        </Paragraph>
      </Space>

      <Title level={2}>{warrantyClaim?.claimNumber}</Title>
      <Text>
        {intl.formatMessage({
          id: 'warrantyClaim.confirmation.ticketNumberReferenceText',
        })}
      </Text>

      <Title level={4}>
        {intl.formatMessage({
          id: 'warrantyClaim.confirmation.warrantyClaimDetails',
        })}
      </Title>
      <ClaimDetailsTable>
        <tbody>
          <tr>
            <td>{intl.formatMessage({ id: 'general.firstName' })}:</td>
            <td>{warrantyClaim?.firstName}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.lastName' })}:</td>
            <td>{warrantyClaim?.lastName}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.company' })}:</td>
            <td>{warrantyClaim?.company}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.phone' })}:</td>
            <td>{warrantyClaim?.phoneNumber}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.email' })}:</td>
            <td>{warrantyClaim?.emailAddress}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.addressLine1' })}:</td>
            <td>{warrantyClaim?.addressLine1}</td>
          </tr>
          {warrantyClaim?.addressLine2 && (
            <tr>
              <td>{intl.formatMessage({ id: 'general.addressLine2' })}:</td>
              <td>{warrantyClaim?.addressLine2}</td>
            </tr>
          )}
          <tr>
            <td>{intl.formatMessage({ id: 'general.city' })}:</td>
            <td>{warrantyClaim?.city}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.stateProvince' })}:</td>
            <td>{warrantyClaim?.region}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.zipPostalCode' })}:</td>
            <td>{warrantyClaim?.postalCode}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'general.country' })}:</td>
            <td>{warrantyClaim?.country}</td>
          </tr>
        </tbody>
      </ClaimDetailsTable>

      <br />
      <Title level={4}>
        {intl.formatMessage({
          id: 'warrantyClaim.confirmation.productInformation',
        })}
      </Title>
      <ClaimDetailsTable>
        <tbody>
          <tr>
            <td>
              {intl.formatMessage({
                id: 'warrantyClaim.productModelNumberToolName',
              })}
              :
            </td>
            <td>{warrantyClaim?.modelNumberToolName}</td>
          </tr>
        </tbody>
      </ClaimDetailsTable>

      {warrantyClaim?.proofOfPurchaseUrl && (
        <React.Fragment>
          <Paragraph>
            {intl.formatMessage({ id: 'warrantyClaim.proofOfPurchase' })}
          </Paragraph>
          <Paragraph>
            <a
              href={warrantyClaim?.proofOfPurchaseUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {warrantyClaim?.proofOfPurchaseUrl.slice(
                warrantyClaim?.proofOfPurchaseUrl.lastIndexOf('/') + 1
              )}
            </a>
          </Paragraph>
        </React.Fragment>
      )}
      {warrantyClaim?.toolImageUrls && (
        <React.Fragment>
          <Paragraph>
            {intl.formatMessage({
              id: 'warrantyClaim.confirmation.toolImages',
            })}
          </Paragraph>
          <Row gutter={[16, 16]}>{toolImages}</Row>
        </React.Fragment>
      )}

      {warrantyClaim?.reasonCodes && (
        <ClaimDetailsTable>
          <tbody>
            <tr>
              <td>
                {intl.formatMessage({ id: 'warrantyClaim.reasonForReturn' })}:
              </td>
              <td>{reasonCodes}</td>
            </tr>
          </tbody>
        </ClaimDetailsTable>
      )}

      <Paragraph>
        {intl.formatMessage({ id: 'warrantyClaim.comments' })}:
      </Paragraph>
      <Paragraph>{warrantyClaim?.reasonForReturn}</Paragraph>
    </Content>
  );

  return (
    <Container style={{ maxWidth: 700, padding: 0 }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'warrantyClaim.confirmation.title',
          })}
        </title>
      </Helmet>
      {emailSettingsLoading ? (
        <Row justify={'center'}>
          <Col>
            <Spin size='large' />
          </Col>
        </Row>
      ) : (
        content
      )}
    </Container>
  );
};
