import { success } from '../utils';
import { GET_MAGNET_SIZES } from '../types';

export const initialState = {
  magnetSizeList: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_MAGNET_SIZES):
      return {
        ...state,
        magnetSizeList: action.payload.data,
      };
    default:
      return state;
  }
};
