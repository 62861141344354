import createAxiosAction from '../createAxiosAction';
import { GET_ACTIVE_FEATURES } from '../types';

export default {
  getActiveFeatures: () => {
    return createAxiosAction({
      url: `/api/v1/configuration/activeFeatures`,
      startAction: GET_ACTIVE_FEATURES,
    });
  },
};
