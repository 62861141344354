import { GET_STATES } from '../types';
import { success } from '../utils';

export const initialState = {
  stateList: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_STATES):
      return {
        ...state,
        stateList: action.payload.data,
      };
    default:
      return state;
  }
};
