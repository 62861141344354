import { useSelector } from 'react-redux';

const useFeatureFlags = () => {
  return {
    searchFiltersWithReplacementsGroup: useSelector(
      (state) =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes(
          'ReplacementsSearchFilterGroup'
        )
    ),
    squaresProductLaunch: useSelector(
      (state) =>
        state.configuration.getActiveFeatures.activeFeatures &&
        state.configuration.getActiveFeatures.activeFeatures.includes(
          'SquaresProductLaunch'
        )
    ),
  };
};

export default useFeatureFlags;
