import {
  Col,
  Divider,
  PageHeader,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { StringParam, useQueryParams } from 'use-query-params';

import { Container } from '../../components/Container';
import { pageContentsActions } from '../../store';
import { cdnHelper } from '../../helpers';
import { useIntl } from 'react-intl';

export const AboutUs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const content = useSelector((state) => state.pageContents.content?.about);
  const [queryParams] = useQueryParams({
    version: StringParam,
  });

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content?.body)
      dispatch(
        pageContentsActions.getPageContent({ ...queryParams, dataKey: 'about' })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  return (
    <Container>
      <PageHeader
        style={{ padding: 0 }}
        title={
          <Space>
            <span>{intl.formatMessage({ id: 'aboutUs.title' })}</span>
          </Space>
        }
        onBack={null}
      />
      <Fade delay={150}>
        <Typography>
          <Divider style={{ margin: '12px 0' }} />
          <Row gutter={[16, 16]} justify='center'>
            <Col xs={0} sm={0} md={9} lg={5} align='center'>
              <img
                src={`https://${cdnHelper.getHost()}/images/about/aboutus.jpg`}
                style={{ maxWidth: '100%' }}
                alt='about-us'
              />
            </Col>
            <Col xs={24} sm={24} md={15} lg={19}>
              {content?.body ? (
                <ReactMarkdown source={content.body} />
              ) : (
                <Skeleton active paragraph={{ rows: 10 }} />
              )}
            </Col>
          </Row>
        </Typography>
      </Fade>
    </Container>
  );
};
