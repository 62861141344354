import {
  GET_PRODUCT,
  SEARCHING_PRODUCTS,
  SET_PRODUCT_DETAILS,
  SET_SEARCH_FILTERS_SIDER,
} from '../types';
import { success } from '../utils';

function constructInitialImageList(imageList, sku) {
  let finalImageList = {};
  for (let i = 0; i < imageList?.length; i++) {
    // Get image name
    let imageName = imageList[i].substring(imageList[i].lastIndexOf('/') + 1);

    finalImageList[imageName] = {
      sku: sku,
      isUploading: false,
      imageUrl: imageList[i],
    };
  }
  return finalImageList;
}

export const initialState = {
  searchResultsLoading: false,
  searchResults: null,
  productDetails: null,
  productDetailsLoading: false,
  searchFiltersSiderCollapsed: null,
  productImageList: [],
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        productDetailsLoading: true,
      };
    case success(GET_PRODUCT):
      return {
        ...state,
        productDetails: action.payload.data,
        productDetailsLoading: false,
        productImageList: constructInitialImageList(
          action.payload.data?.imageUrls,
          action.payload.data?.sku
        ),
      };

    case SEARCHING_PRODUCTS:
      return {
        ...state,
        searchResultsLoading: true,
      };
    case success(SEARCHING_PRODUCTS):
      return {
        ...state,
        searchResultsLoading: false,
        searchResults: action.payload,
      };
    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetailsLoading: false,
        productDetails: action.product,
        productImageList: constructInitialImageList(
          action.product?.imageUrls,
          action.product?.sku
        ),
      };

    case SET_SEARCH_FILTERS_SIDER:
      return {
        ...state,
        searchFiltersSiderCollapsed: action.collapsed,
      };

    default:
      return state;
  }
};
