import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DisclaimerText,
  InternalLink,
  ExternalLink,
  Container,
} from './styled';

export const JustInTimeNotice = ({ textColor, buttonType }) => {
  const intl = useIntl();

  const [fontColor, setFontColor] = useState('white');
  const [linkColor, setLinkColor] = useState('#305ea4');

  useEffect(() => {
    setFontColor(textColor === 'light' ? 'white' : 'black');
    setLinkColor(textColor === 'light' ? 'white' : '#305ea4');
  }, [textColor]);

  return (
    <Container>
      <DisclaimerText textColor={fontColor}>
        {intl.formatMessage({ id: 'justInTimeNotice.disclaimer1' })}
        {intl.formatMessage({ id: `justInTimeNotice.${buttonType}` })}
        {intl.formatMessage({ id: 'justInTimeNotice.disclaimer2' })}
        {intl.formatMessage({ id: 'justInTimeNotice.information' })}
        <InternalLink to='/privacy-policy' textColor={linkColor}>
          {intl.formatMessage({ id: 'justInTimeNotice.privacyNoticeLink' })}
        </InternalLink>
        {intl.formatMessage({ id: 'justInTimeNotice.dataRights' })}
        <ExternalLink
          textColor={linkColor}
          href='https://privacyportal-cdn.onetrust.com/dsarwebform/0bcd3d0d-df9d-4291-9bd5-f276c7dae8aa/7a34ccd2-b707-4539-bf00-9caa1f347b59.html'
          target='_blank'
        >
          {intl.formatMessage({
            id: 'justInTimeNotice.yourPrivacyChoicesLink',
          })}
        </ExternalLink>
      </DisclaimerText>
    </Container>
  );
};
