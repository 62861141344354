/* eslint-disable jsx-a11y/anchor-is-valid */
import './newsletterStyles.css';
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import { BackTop, Col, Layout, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';
import { Container, Navbar } from '../../components';
import { withAppInsights, googleAnalyticsHelper } from '../../helpers';
import { useNavigationLinks } from '../../hooks';
import { pageContentsActions, configurationActions } from '../../store';
import { FooterSection } from './FooterSection';
import privacyOptions from '../../assets/svgs/privacy-options.svg';
import {
  FooterButton,
  FooterContainer,
  FooterExternalLink,
  FooterLink,
  FooterMenu,
  FooterSpace,
  MainContent,
  MegaFooter,
  StyledFooter,
  PrivacyImage,
} from './styled';
import { NewsletterSignUpFooter } from './NewsletterSignUp/NewsletterSignUpFooter';

export const SiteLayout = withAppInsights(({ children, theme }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { productLinks, whereToBuyLinks, supportLinks } = useNavigationLinks();

  // App State
  const content = useSelector((state) => state.pageContents.content?.global);
  const [queryParams] = useQueryParams({
    version: StringParam,
  });

  // local state
  const [, setLoading] = useState(!content);

  useEffect(() => {
    dispatch(configurationActions.getActiveFeatures());
  }, []);

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content)
      dispatch(
        pageContentsActions.getPageContent({
          ...queryParams,
          dataKey: 'global',
        })
      ).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  const onClickHandler = (url) => {
    googleAnalyticsHelper('external link', 'click', url);

    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const onClickPDFHandler = (url) => {
    googleAnalyticsHelper('pdf interaction', 'page change', 'catalog');

    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const toggleOneTrustPreferences = () => {
    window.OneTrust.ToggleInfoDisplay();
  };

  return (
    <React.Fragment>
      <Layout style={{ minHeight: '100vh' }}>
        <Navbar />
        <MainContent theme={theme}>{children}</MainContent>
        {content && <NewsletterSignUpFooter />}
        <StyledFooter id='footer'>
          <MegaFooter>
            <Container style={{ width: '100%', display: 'flex' }}>
              <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col xs={12} md={8} lg={6} xl={3}>
                  <FooterSection
                    links={productLinks}
                    title={intl.formatMessage({ id: 'navigation.products' })}
                  />
                </Col>
                <Col xs={12} md={8} lg={6} xl={3}>
                  <FooterSection
                    links={whereToBuyLinks}
                    title={intl.formatMessage({
                      id: 'navigation.whereToBuy',
                    })}
                  />
                </Col>
                <Col xs={12} md={8} lg={6} xl={3}>
                  <FooterSection
                    links={supportLinks}
                    title={intl.formatMessage({ id: 'navigation.support' })}
                  />
                </Col>
                <Col xs={12} md={8} lg={6} xl={3}>
                  <FooterSection
                    links={[
                      {
                        title: intl.formatMessage({
                          id: 'navigation.stilettoStory',
                        }),
                        to: '/about',
                      },
                    ]}
                    title={intl.formatMessage({ id: 'navigation.about' })}
                  />
                </Col>
                {content?.cataloguePDF && content?.catalogueThumbnail && (
                  <Col xs={12} md={8} lg={6} xl={3}>
                    <FooterSection
                      title={intl.formatMessage({ id: 'footer.catalog' })}
                    >
                      <a
                        onClick={() => onClickPDFHandler(content?.cataloguePDF)}
                      >
                        <img
                          width={80}
                          src={content?.catalogueThumbnail}
                          alt='catalog'
                        />
                      </a>
                    </FooterSection>
                  </Col>
                )}
                <Col xs={12} md={8} lg={6} xl={3}>
                  <FooterSection
                    title={intl.formatMessage({ id: 'footer.social' })}
                  >
                    <Space direction='horizontal'>
                      <FooterExternalLink
                        onClick={() =>
                          onClickHandler(
                            'https://www.facebook.com/StilettoTools'
                          )
                        }
                      >
                        <FacebookOutlined style={{ fontSize: '2em' }} />
                      </FooterExternalLink>
                      <FooterExternalLink
                        onClick={() =>
                          onClickHandler('https://twitter.com/Stiletto_Tools')
                        }
                      >
                        <TwitterOutlined style={{ fontSize: '2em' }} />
                      </FooterExternalLink>
                      <FooterExternalLink
                        onClick={() =>
                          onClickHandler(
                            'https://www.instagram.com/stilettotools/'
                          )
                        }
                      >
                        <InstagramOutlined style={{ fontSize: '2em' }} />
                      </FooterExternalLink>
                    </Space>
                  </FooterSection>
                </Col>
              </Row>
            </Container>
          </MegaFooter>
          <FooterMenu>
            <FooterContainer>
              <FormattedMessage
                id='footer.copyright'
                values={{ year: new Date().getFullYear() }}
              />
              <FooterSpace>
                <FooterLink to='/legal'>
                  <FormattedMessage id='footer.legal' />
                </FooterLink>
                <span>|</span>
                <FooterLink to='/privacy-policy'>
                  <FormattedMessage id='footer.privacy' />
                </FooterLink>
                <span>|</span>
                <FooterExternalLink
                  href='https://privacyportal-cdn.onetrust.com/dsarwebform/0bcd3d0d-df9d-4291-9bd5-f276c7dae8aa/7a34ccd2-b707-4539-bf00-9caa1f347b59.html'
                  target='_blank'
                >
                  <FormattedMessage id='footer.privacyChoices' />
                  <PrivacyImage src={privacyOptions} alt={'privacy options'} />
                </FooterExternalLink>
                <span>|</span>
                <FooterButton
                  id='ot-sdk-btn'
                  onClick={toggleOneTrustPreferences}
                >
                  <FormattedMessage id='footer.cookiePreferences' />
                </FooterButton>
              </FooterSpace>
            </FooterContainer>
          </FooterMenu>
        </StyledFooter>
      </Layout>
      <BackTop />
    </React.Fragment>
  );
});
