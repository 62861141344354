import { Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const UploadButton = ({ isUploading }) => {
  return (
    <div>
      {isUploading ? <Spin spinning={true} /> : <PlusOutlined />}
      <div className='ant-upload-text'>
        {isUploading ? (
          <FormattedMessage id='general.uploadPending' />
        ) : (
          <FormattedMessage id='general.upload' />
        )}
      </div>
    </div>
  );
};
