import { Divider, PageHeader } from 'antd';
import React from 'react';

import { Container, PageLoader, TabLink } from '../../../components';
import { SiteLayout } from '../../SiteLayout';
import { useFeatureFlags } from '../../../hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NotFound } from '../../NotFound';

export const CategoryLayout = ({ children, titleKey }) => {
  const intl = useIntl();
  const { squaresProductLaunch } = useFeatureFlags();

  // app state
  const configurationLoading = useSelector(
    (state) => state.configuration.getActiveFeatures.isLoading
  );

  const categoryPageMarkup =
    titleKey === 'navigation.squares' && !squaresProductLaunch ? (
      <NotFound />
    ) : (
      <>
        <Container style={{ paddingBottom: 0 }}>
          <PageHeader
            style={{ padding: 0 }}
            title={intl.formatMessage({ id: titleKey })}
            extra={[
              <TabLink key='striking-tools' to='/category/striking-tools'>
                <FormattedMessage id='navigation.strikingTools' />
              </TabLink>,
              squaresProductLaunch ? (
                <TabLink key='squares' to='/category/squares'>
                  <FormattedMessage id='navigation.squares' />
                </TabLink>
              ) : null,
              <TabLink key='pry-bars' to='/category/pry-bars'>
                <FormattedMessage id='navigation.pryBars' />
              </TabLink>,
              <TabLink key='accessories' to='/category/accessories'>
                <FormattedMessage id='navigation.accessories' />
              </TabLink>,
            ]}
          />
          <Divider />
        </Container>
        <Container>{children}</Container>
      </>
    );

  return (
    <SiteLayout>
      {configurationLoading ? <PageLoader /> : categoryPageMarkup}
    </SiteLayout>
  );
};
