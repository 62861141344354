import { Divider, PageHeader } from 'antd';
import React from 'react';

import { Container, TabLink } from '../../../../components';
import { SiteLayout } from '../../../SiteLayout';
import { FormattedMessage, useIntl } from 'react-intl';

export const WarrantyLayout = ({ children }) => {
  const intl = useIntl();
  return (
    <SiteLayout>
      <Container>
        <PageHeader
          style={{ padding: 0 }}
          title={intl.formatMessage({ id: 'warrantyClaim.warranty' })}
          extra={[
            <TabLink key='warranty' to='/warranty'>
              <FormattedMessage id='warrantyClaim.layout.claim' />
            </TabLink>,
            <TabLink key='warranty-policy' to='/warranty-policy'>
              <FormattedMessage id='warrantyClaim.layout.policy' />
            </TabLink>,
          ]}
        />
        <Divider />
        {children}
      </Container>
    </SiteLayout>
  );
};
