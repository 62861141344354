import { Layout, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Container } from '../../../../components/Container';
import { TicketDetailsTable } from './styled';
import { useIntl } from 'react-intl';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export const ContactUsConfirmation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // App State
  const contactUs = useSelector((state) => state.contactUs.contactUs);

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!contactUs) {
      history.push('/notfound');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUs, dispatch]);

  return (
    <Container style={{ maxWidth: 700, padding: 0 }}>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'contactUs.confirmation.title' })}
        </title>
      </Helmet>
      <Content>
        <Title level={2}>
          {intl.formatMessage({ id: 'contactUs.confirmation.subTitleLine1' })}
        </Title>
        <Title level={4}>
          {intl.formatMessage({ id: 'contactUs.confirmation.subTitleLine2' })}
        </Title>
        <Paragraph>
          {intl.formatMessage({ id: 'contactUs.confirmation.body' })}
        </Paragraph>
        <Space direction='vertical' size={1}>
          <Paragraph>
            {intl.formatMessage({ id: 'contactUs.confirmation.questions' })}
          </Paragraph>
          <Text>
            <a href='tel:1 (800) 987-1849'>1 (800) 987-1849</a> (USA/CAN)
          </Text>
          <Paragraph style={{ marginTop: '10px' }}>
            <Link to='/'>
              {intl.formatMessage({ id: 'general.stilettoWebsite' })}
            </Link>
          </Paragraph>
        </Space>

        <Title level={2}>{contactUs?.ticketNumber}</Title>
        <Text>
          {intl.formatMessage({
            id: 'contactUs.confirmation.ticketNumberText',
          })}
        </Text>

        <Title level={4}>
          {' '}
          {intl.formatMessage({ id: 'contactUs.confirmation.yourEmailText' })}
        </Title>
        <TicketDetailsTable>
          <tbody>
            <tr>
              <td>{intl.formatMessage({ id: 'general.firstName' })}:</td>
              <td>{contactUs?.firstName}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'general.lastName' })}:</td>
              <td>{contactUs?.lastName}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'general.phone' })}:</td>
              <td>{contactUs?.phoneNumber}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'general.email' })}:</td>
              <td>{contactUs?.emailAddress}</td>
            </tr>
            <tr>
              <td>{intl.formatMessage({ id: 'general.country' })}:</td>
              <td>{contactUs?.country}</td>
            </tr>
          </tbody>
        </TicketDetailsTable>

        <Paragraph style={{ marginTop: '10px' }}>
          {contactUs?.message}
        </Paragraph>
      </Content>
    </Container>
  );
};
