import styled from 'styled-components';
import { Row } from 'antd';

export const HeroBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.7);
  margin: 0 24px;
  height: 100%;
  padding: 50px;
  max-height: 560px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 480px) {
    margin: 0;
    padding: 24px;
    height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

export const HeroContainer = styled(Row)`
  height: 500px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const HeroTitle = styled.h1`
  margin-top: 0;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 26px;
  color: white;
`;

export const HeroSubTitle = styled.h2`
  margin-top: 0;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: white;
`;

export const HomeSectionTitle = styled.h2`
  margin-top: 0;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #2b2b2b;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
`;

export const FeaturedTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0.6rem;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 26px;
  color: #2b2b2b;
`;

export const FeaturedSubtitle = styled.h2`
  margin-top: 0;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #2b2b2b;
`;

export const FeaturedProductWrapper = styled.div`
  background-color: #f2f2f2;
  height: 100%;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const FeaturedImage = styled.img`
  max-width: 100%;
  flex: 0 0 auto; /* IE */
  object-fit: scale-down; /* FF */
  height: 360px;
`;

export const FeaturedDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionSubtitle = styled.div`
  margin-top: 0;
  position: relative;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #2e363d;
`;

export const Testimonial = styled.div`
  font-family: 'Neue Helvetica W05 Condensed', Arial, Helvetica, sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 46px;
  color: #4a4a4a;
`;

export const TestimonialAuthor = styled.div`
  font-family: 'Neue Helvetica W05 Condensed', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 1rem;
`;
