import { Descriptions, Typography } from 'antd';
import styled from 'styled-components';
import { SafetyOutlined } from '@ant-design/icons';

import { Link } from '../../components';

const { Paragraph, Text } = Typography;

export const ProductContainer = styled.div`
  background-color: #2b2b2b;
`;

export const SpecificationsContainer = styled.div`
  background-color: #2b2b2b;
  color: white;
  padding: 1rem 0;
  margin-left: auto;
  margin-right: auto;
`;

export const CarouselImageContainer = styled.div``;

export const Specifications = styled(Descriptions)`
  .ant-descriptions-row {
    background: white !important;
  }

  .ant-descriptions-title {
    margin-bottom: 5px;
  }
`;

export const ProductSku = styled(Text)``;

export const ProductTitle = styled.h2`
  margin-top: 0;
`;

export const ProductInformation = styled(Paragraph)``;

export const SpecificationsTable = styled.table`
  td {
    padding-right: 2rem; /* Apply cell padding */
  }
`;

export const WarrantyLink = styled(Link)`
  font-weight: bold;
  display: flex;
`;

export const WarrantyIcon = styled(SafetyOutlined)`
  padding-right: 5px;
  font-size: 20px;
`;
