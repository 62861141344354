import { GET_INTERNATIONAL_LOCATIONS } from '../types';
import { error, success } from '../utils';

export const initialState = {
  isError: false,
  internationalLocations: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_INTERNATIONAL_LOCATIONS:
      return {
        ...state,
        isError: false,
      };
    case success(GET_INTERNATIONAL_LOCATIONS):
      return {
        ...state,
        isError: false,
        internationalLocations: action.payload.data,
      };
    case error(GET_INTERNATIONAL_LOCATIONS):
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};
