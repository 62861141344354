import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';


import {
  FeaturedDetails,
  FeaturedImage,
  FeaturedProductWrapper,
  FeaturedSubtitle,
  FeaturedTitle,
} from '../styled';

export const FeaturedProduct = ({
  title,
  subText,
  image,
  hyperlinkText,
  hyperlinkUrl,
  priceSpiderSku
}) => {

  return (
    <FeaturedProductWrapper>
      <Row justify='center' style={{ height: '100%' }}>
        <Col
          xs={{ order: 1, span: 24 }}
          md={{ order: 2, span: 12 }}
          style={{
            padding: '24px',
            paddingBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <FeaturedTitle>{title}</FeaturedTitle>
            <FeaturedDetails>
              <FeaturedSubtitle>{subText}</FeaturedSubtitle>
              {hyperlinkText && hyperlinkUrl && (
                <Link to={hyperlinkUrl} style={{ color: '#2C5EA5' }}>
                  {hyperlinkText} <RightOutlined />
                </Link>
              )}
            </FeaturedDetails>
          </div>
          {priceSpiderSku && (
            <div style={{ padding: '1rem 0' }}>
              <div
                className='ps-widget'
                ps-sku={priceSpiderSku}
                id='where-to-buy'
              ></div>
              {window.PriceSpider.rebind()}
            </div>
          )}
        </Col>
        <Col
          xs={{ order: 1, span: 24 }}
          md={{ order: 2, span: 12 }}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <FeaturedImage src={image} />
        </Col>
      </Row>
    </FeaturedProductWrapper>
  );
};
