import React from 'react';
import { useIntl } from 'react-intl';

import { useSelector } from 'react-redux';

export const ProductDimensionMapping = () => {
  const productDetails = useSelector((state) => state.products.productDetails);
  const intl = useIntl();

  // Set order of product dimensions display
  // Bandaid solution for dictating the order of dimensions
  const dimensionsOrder = [
    'lengthInches',
    'widthInches',
    'heightInches',
    'depthInches',
    'totalWeightOunces',
    'weightOfHeadOunces',
  ];

  function getUnitType(keyString) {
    let keyStringLower = keyString.toString().toLowerCase();

    if (keyStringLower.includes('inches')) {
      return '"';
    } else if (keyStringLower.includes('ounces')) {
      return 'oz';
    } else {
      return '';
    }
  }

  return productDetails?.dimensions != null
    ? Object.keys(productDetails?.dimensions)
        .sort((a, b) => dimensionsOrder.indexOf(a) - dimensionsOrder.indexOf(b))
        .map(function (key, index) {
          return (
            <tr key={`dimension-${index}`}>
              <td>
                {intl.formatMessage({
                  id: 'productDetails.dimensions.' + key,
                })}
              </td>
              <td>
                {productDetails?.dimensions[key]}
                {getUnitType(key)}
              </td>
            </tr>
          );
        })
    : [];
};
