import { Divider, PageHeader } from 'antd';
import React from 'react';

import { Container } from '../../../../components';
import { SiteLayout } from '../../../SiteLayout';
import { useIntl } from 'react-intl';

export const FAQLayout = ({ children }) => {
  const intl = useIntl();

  return (
    <SiteLayout>
      <Container>
        <PageHeader
          style={{ padding: 0 }}
          title={intl.formatMessage({ id: 'faq.frequentlyAskedQuestions' })}
        />
        <Divider />
        {children}
      </Container>
    </SiteLayout>
  );
};
