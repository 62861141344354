import { Menu, Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';

const { SubMenu } = Menu;

export const LogoWrapper = styled.div`
  width: 184px;
  height: 100%;
  float: left;
  display: flex;
  cursor: pointer;

  img,
  svg {
    align-self: center;
  }
`;

export const TopNavMenu = styled(Menu)`
  min-width: 600px;

  .ant-menu-submenu:hover,
  .ant-menu-submenu-active {
    .ant-menu-submenu-title {
      color: white;
    }
  }

  .ant-menu-sub {
    min-width: 220px !important;
  }

  .ant-menu-submenu-arrow::before {
    width: 18px !important;
    height: 18px !important;
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;

export const TopSubMenu = styled(SubMenu)`
  float: left;
`;

export const Drawer = styled(AntDrawer)`
  margin-top: 64px;

  .ant-drawer-body {
    padding: 0 !important;
  }

  @media (max-width: 420px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;

export const DrawerMenu = styled(Menu)`
  border: none;
  padding: 0 !important;
  height: 100%;
  min-height: 800px;
  overflow-y: auto;
`;

export const DrawerSubMenu = styled(SubMenu)`
  width: 100%;
`;
