import createAxiosAction from '../createAxiosAction';
import { GET_MAGNET_SIZES } from '../types';

export default {
  getMagnetSizes: () => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/magnetSizes',
      startAction: GET_MAGNET_SIZES,
    });
  },
};
