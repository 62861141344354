import styled from 'styled-components';
import { Container } from '../../../components/Container';

export const LocationContainer = styled(Container)`
  max-width: 600px;
  padding: 2rem 1rem;
`;

export const Location = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
`;

export const CompanyLink = styled.a`
  color: #305ea4;
`;
