import styled from 'styled-components';

export const ProductCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const ProductDetails = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  user-select: text;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f6f6f6;
  min-height: 320px;
`;

export const ProductImage = styled.img`
  max-height: 300px;
  max-width: 100%;
  flex: 0 0 auto; /* IE */
  object-fit: scale-down; /* FF */
`;
