import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FeaturedTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 0.6rem;
  position: relative;
  font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 26px;
  color: #2b2b2b;
`;

export const FeaturedProductWrapper = styled(Link)`
  border: 1px solid darkgray;
  display: flex;
  height: 100%;

  :focus,
  :hover {
    text-decoration: none;
    outline: 3px solid darkgray;
  }

  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const FeaturedDetails = styled.div`
  margin-left: 20px;
`;

export const FeaturedImage = styled.img`
  max-width: 100%;
  flex: 0 0 auto; /* IE */
  object-fit: scale-down; /* FF */
  max-height: 200px;
  width: 120px;
`;

export const FeaturedLink = styled.span`
  color: #2c5ea5;
`;
