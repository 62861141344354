import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from 'react-redux';

export const ProductSpecificationMapping = () => {
  const productDetails = useSelector((state) => state.products.productDetails);
  const intl = useIntl();

  // Set order of product specs display
  // Bandaid solution for dictating the order of specs
  const specificationsOrder = [
    'handleType',
    'materialType',
    'colorFamily',
    'hammerType',
    'handToolType',
    'handleMaterial',
    'replaceableHead',
    'face',
    'sidingOrExtTrim',
    'remodeling',
    'framing',
    'poleBarn',
    'concrete',
    'scaffolding',
    'finish',
    'drywall',
    'roofing',
    'wireLathe',
  ];

  return productDetails?.metadata != null
    ? Object.keys(productDetails?.metadata)
        .filter((key) => productDetails.metadata[key] !== '')
        .sort(
          (a, b) =>
            specificationsOrder.indexOf(a) - specificationsOrder.indexOf(b)
        )
        .map(function (key, index) {
          return (
            <tr key={`dimension-${index}`}>
              <td>
                {intl.formatMessage({
                  id: 'productDetails.metadata.' + key,
                })}
              </td>
              <td>
                {/*Check if metadata return type is a boolean. Return Yes/No if boolean, else return metadata data*/}
                {typeof productDetails?.metadata[key] === 'boolean' ? (
                  productDetails?.metadata[key] ? (
                    <FormattedMessage id='general.yes' />
                  ) : (
                    <FormattedMessage id='general.no' />
                  )
                ) : (
                  productDetails?.metadata[key]
                )}
              </td>
            </tr>
          );
        })
    : [];
};
