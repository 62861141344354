import { Button } from 'antd';
import styled from 'styled-components';

export const FiltersButton = styled(Button)`
  margin-right: 0.5rem;
  border: 1px solid #b2b2b2;
  display: ${() =>
    window.location.pathname === '/search' ? 'inline' : 'none'};
    
  @media (min-width: 769px) {
    display: none;
`;
