import {
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  HighlightButton,
  AutoCompleteInput,
  Container,
} from '../../../../components';
import {
  countriesActions,
  magnetSizesActions,
  pageContentsActions,
  reasonCodesActions,
  statesActions,
  warrantyClaimActions,
} from '../../../../store';
import { HoneyPotFormItems } from './components/HoneypotFormItems';
import { cdnHelper, fbTrack, googleAnalyticsHelper } from '../../../../helpers';
import { useIntl } from 'react-intl';
import { UploadButton } from './components/UploadButton';
import { WarrantyDocuments } from './components/WarrantyDocuments';
import { JustInTimeNotice } from '../../../../components/JustInTimeNotice';

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const justInTimeColorScheme = 'dark';
const justiInTimeButtonType = 'sendButton';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const SubmitWarrantyClaim = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // App State
  const stateList = useSelector((state) => state.states.stateList);
  const content = useSelector((state) => state.pageContents.content?.warranty);
  const [queryParams] = useQueryParams({
    version: StringParam,
  });
  const countryList = useSelector((state) => state.countries.countryList);
  const suggestions = useSelector(
    (state) => state.warrantyClaim.productSuggestions,
    shallowEqual
  );
  const reasonCodeList = useSelector(
    (state) => state.reasonCodes.reasonCodeList
  );
  const magnetSizeList = useSelector(
    (state) => state.magnetSizes.magnetSizeList
  );

  // Local state
  const [uploadingProofOfPurchase, setUploadingProofOfPurchase] =
    useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    toolImageFileList: [],
    proofOfPurchaseUrl: [],
    selectedCountry: 'US',
  });

  const {
    previewVisible,
    previewImage,
    toolImageFileList,
    previewTitle,
    proofOfPurchaseUrl,
  } = state;
  const [chosenReasonCodes, setChosenReasonCodes] = useState([]);
  const [showMagnetSize, setShowMagnetSize] = useState(false);

  useEffect(() => {
    if (chosenReasonCodes.includes('Magnet Missing/Fell Out')) {
      setShowMagnetSize(true);
    } else {
      setShowMagnetSize(false);
    }
  }, [chosenReasonCodes]);

  useEffect(() => {
    if (!countryList) {
      dispatch(countriesActions.getCountries());
    }
  }, [dispatch, countryList]);

  useEffect(() => {
    if (!stateList) {
      dispatch(statesActions.getStates());
    }
  }, [dispatch, stateList]);

  useEffect(() => {
    if (!reasonCodeList) {
      dispatch(reasonCodesActions.getReasonCodes());
    }
  }, [dispatch, reasonCodeList]);

  useEffect(() => {
    if (!magnetSizeList) {
      dispatch(magnetSizesActions.getMagnetSizes());
    }
  }, [dispatch, magnetSizeList]);

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content?.body)
      dispatch(
        pageContentsActions.getPageContent({
          ...queryParams,
          dataKey: 'warranty',
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  const stateOptions =
    stateList &&
    stateList.map((item, index) => {
      return (
        <Option key={index} value={item.abbreviation}>
          {item.name}
        </Option>
      );
    });

  const countryOptions =
    countryList &&
    countryList.map((item, index) => {
      return (
        <Option key={index} value={item.iso}>
          {item.name}
        </Option>
      );
    });

  const reasonCodeOptions =
    reasonCodeList &&
    reasonCodeList.map((item, index) => {
      return (
        <Option key={index} value={item.description}>
          {item.description}
        </Option>
      );
    });

  const magnetSizeOptions =
    magnetSizeList &&
    magnetSizeList.map((item, index) => {
      return (
        <Option key={index} value={item.size}>
          {item.size}
        </Option>
      );
    });

  /***
   * onSubmit will save the warranty info
   * @param values: the values of the form
   */
  const onSubmit = (values) => {
    if (
      validateForm(values) &&
      proofOfPurchaseUrl.length !== 0 &&
      toolImageFileList.length !== 0
    ) {
      //Submit
      setSaving(true);
      fbTrack('customtrack', 'WARRANTY_SUBMIT');

      googleAnalyticsHelper('form submit', 'button click', 'warranty claim');

      let val = {
        FirstName: values.firstNameMke,
        LastName: values.lastNameMke,
        Company: values.companyMke,
        PhoneNumber: values.phoneMke,
        EmailAddress: values.emailMke,
        AddressLine1: values.address1Mke,
        AddressLine2: values.address2Mke,
        City: values.cityMke,
        Region: values.regionMke,
        Country: values.countryMke,
        PostalCode: values.postalMke,
        ModelNumberToolName: values.modelNumberToolNameMke,
        ProofOfPurchaseUrl:
          state.proofOfPurchaseUrl.length > 0
            ? state.proofOfPurchaseUrl.slice(-1).map((item) => item.url)[0]
            : null,
        ToolImageUrls:
          state.toolImageFileList.length > 0
            ? state.toolImageFileList.slice(-5).map((item) => item.url)
            : null,
        ReasonForReturn: values.reasonForReturnMke,
        ReasonCodes: values.reasonCodesMke,
        MagnetSize: values.magnetSize,
        PlaceOfPurchase: values.placeOfPurchase,
        DateOfPurchase: values.dateOfPurchase,
      };
      dispatch(warrantyClaimActions.insertWarrantyClaim(val))
        .then((res) => {
          message.success(
            intl.formatMessage({ id: 'general.genericSuccessSubmitMessage' })
          );
          setSaving(false);
          history.push(`/warranty/${res.data.data.claimNumber}`);
        })
        .catch(() => {
          setSaving(false);
          message.error(
            intl.formatMessage({ id: 'general.genericErrorMessage' })
          );
        });
    } else {
      // Do something
    }
  };

  const validateForm = (formVal) => {
    let honeypotFields = [
      'firstName',
      'lastName',
      'company',
      'phoneNumber',
      'emailAddress',
      'address1',
      'address2',
      'city',
      'state',
      'postalCode',
      'country',
      'modelNumberToolName',
      'reasonForReturn',
    ];
    honeypotFields.forEach((field) => {
      if (typeof formVal[field] !== 'undefined') {
        return false;
      }
    });

    return true;
  };

  function constructImageUrls(currentFileList, fileToBeUpdated, filePath) {
    let updatedList = currentFileList;
    let imageName = fileToBeUpdated.name;
    let imageObj = {
      uid: fileToBeUpdated.uid,
      name: imageName,
      status: 'done',
      url: `https://${cdnHelper.getHost()}/${filePath}`,
    };

    updatedList.push(imageObj);

    return updatedList;
  }

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const props = {
    onRemove: (file) => {
      setState((state) => {
        const index = state.toolImageFileList.indexOf(file);
        const newFileList = state.toolImageFileList.slice();
        newFileList.splice(index, 1);

        return {
          ...state,
          toolImageFileList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      let fileToBeAdded = file;
      const isLt2M = file.size / 1024 / 1024 <= 20;
      if (!isLt2M) {
        message.error(<FormattedMessage id='validation.fileSizeUnder20MB' />);
        return false;
      }

      setUploadingImage(true);
      dispatch(warrantyClaimActions.uploadImage(file))
        .then((res) => {
          message.success(
            intl.formatMessage(
              { id: 'warrantyClaim.fileUploadSuccess' },
              { fileName: file.name }
            )
          );
          let newFileList = constructImageUrls(
            state.toolImageFileList,
            fileToBeAdded,
            res.data
          );

          setState({ ...state, toolImageFileList: newFileList });
          return true;
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            message.error(
              <FormattedMessage id='validation.unsupportedFileType' />
            );
          } else {
            message.error(
              `${(<FormattedMessage id='validation.errorUploadingFile' />)} ${
                file.name
              }.`
            );
          }
          return false;
        })
        .finally(() => setUploadingImage(false));

      return false;
    },
    toolImageFileList,
  };

  const proofOfPurchaseProps = {
    onRemove: (file) => {
      setState({ ...state, proofOfPurchaseUrl: [] });
    },
    beforeUpload: (file) => {
      let fileToBeAdded = file;
      const isLt2M = file.size / 1024 / 1024 <= 20;
      if (!isLt2M) {
        message.error(<FormattedMessage id='validation.fileSizeUnder20MB' />);
      } else {
        setUploadingProofOfPurchase(true);
        dispatch(warrantyClaimActions.uploadDocFile(file))
          .then((res) => {
            message.success(
              intl.formatMessage(
                { id: 'warrantyClaim.fileUploadSuccess' },
                { fileName: file.name }
              )
            );
            let newFileList = constructImageUrls(
              state.proofOfPurchaseUrl,
              fileToBeAdded,
              res.data
            );

            setState({ ...state, proofOfPurchaseUrl: newFileList });
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              message.error(
                <FormattedMessage id='validation.unsupportedFileType' />
              );
            } else {
              message.error(
                `${(<FormattedMessage id='validation.errorUploadingFile' />)} ${
                  file.name
                }.`
              );
            }
          })
          .finally(() => setUploadingProofOfPurchase(false));
      }

      return false;
    },
    toolImageFileList,
  };

  const handleSuggestProducts = (query) => {
    if (query)
      dispatch(warrantyClaimActions.suggestProducts(query)).catch(() =>
        message.error('Something went wrong while getting suggestions...')
      );
  };

  return (
    <Container style={{ padding: 0 }}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'warrantyClaim.title' })}</title>
      </Helmet>
      <Content>
        <Typography>
          <Fade delay={150}>
            <Row gutter={[48, 24]}>
              <WarrantyDocuments content={content} />
              <Col xs={24} lg={12}>
                <Form
                  name='dynamic_form_nest_item'
                  onFinish={onSubmit}
                  hideRequiredMark={false}
                  layout='vertical'
                >
                  <Title level={4}>
                    {intl.formatMessage({ id: 'warrantyClaim.submitAClaim' })}
                  </Title>
                  <Paragraph>
                    Please review the Stiletto warranty policy before submitting
                    a claim
                  </Paragraph>
                  <Form.Item
                    name='firstNameMke'
                    label={<FormattedMessage id='general.firstName' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='given-name' />
                  </Form.Item>
                  <Form.Item
                    name='lastNameMke'
                    label={<FormattedMessage id='general.lastName' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='family-name' />
                  </Form.Item>
                  <Form.Item
                    name='companyMke'
                    label={<FormattedMessage id='general.company' />}
                    initialValue=''
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='organization' />
                  </Form.Item>
                  <Form.Item
                    name='phoneMke'
                    label={<FormattedMessage id='general.phone' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                      {
                        pattern:
                          '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
                        message: (
                          <FormattedMessage id='validation.invalidPhoneNumber' />
                        ),
                      },
                    ]}
                  >
                    <Input name='tel' inputMode='tel' />
                  </Form.Item>
                  <Form.Item
                    name='emailMke'
                    label={<FormattedMessage id='general.email' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                      {
                        type: 'email',
                        message: (
                          <FormattedMessage id='validation.invalidEmail' />
                        ),
                      },
                    ]}
                  >
                    <Input name='email' inputMode='email' />
                  </Form.Item>
                  <Form.Item
                    name='countryMke'
                    label={<FormattedMessage id='general.country' />}
                    initialValue={state.selectedCountry}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Select
                      placeholder={
                        <FormattedMessage id='placeholder.selectACountry' />
                      }
                      onChange={(val) => {
                        setState({ ...state, selectedCountry: val });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp='children'
                      showSearch
                    >
                      {countryOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='address1Mke'
                    label={<FormattedMessage id='general.addressLine1' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='address-line1' />
                  </Form.Item>
                  <Form.Item
                    name='address2Mke'
                    label={<FormattedMessage id='general.addressLine2' />}
                    initialValue=''
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='address-line2' />
                  </Form.Item>
                  <Form.Item
                    name='cityMke'
                    label={<FormattedMessage id='general.city' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='address-level2' />
                  </Form.Item>
                  <Form.Item
                    name='regionMke'
                    label={
                      state.selectedCountry === 'US' ? (
                        <FormattedMessage id='general.state' />
                      ) : (
                        <FormattedMessage id='general.region' />
                      )
                    }
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    {state.selectedCountry !== 'US' ? (
                      <Input name='address-level1' />
                    ) : (
                      <Select
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp='children'
                        showSearch
                      >
                        <Option value=''>
                          {intl.formatMessage({ id: 'general.pleaseSelect' })}
                        </Option>
                        {stateOptions}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    name='postalMke'
                    label={<FormattedMessage id='general.zipPostalCode' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='postal-code' />
                  </Form.Item>
                  <Title level={4}>
                    <FormattedMessage id='warrantyClaim.toolInformationTitle' />
                  </Title>
                  <Form.Item
                    label={
                      <FormattedMessage id='warrantyClaim.productModelNumberToolName' />
                    }
                    name='modelNumberToolNameMke'
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <AutoCompleteInput
                      onSearch={handleSuggestProducts}
                      suggestions={suggestions}
                      style={{ marginBottom: '8px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name='placeOfPurchase'
                    label={
                      <FormattedMessage id='warrantyClaim.placeOfPurchase' />
                    }
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input maxLength={500} name='placeOfPurchase' />
                  </Form.Item>
                  <Form.Item
                    name='dateOfPurchase'
                    label={
                      <FormattedMessage id='warrantyClaim.dateOfPurchase' />
                    }
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <DatePicker name='dateOfPurchase' />
                  </Form.Item>
                  <Form.Item
                    name='proofOfPurchase'
                    label={
                      <FormattedMessage id='warrantyClaim.proofOfPurchase' />
                    }
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Upload
                      {...proofOfPurchaseProps}
                      listType='picture-card'
                      fileList={proofOfPurchaseUrl?.slice(-1)}
                      onPreview={handlePreview}
                      multiple={false}
                    >
                      {proofOfPurchaseUrl?.length >= 1 ? null : (
                        <UploadButton isUploading={uploadingProofOfPurchase} />
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name='toolImageUpToFive'
                    label={
                      <FormattedMessage id='warrantyClaim.toolImageUpToFive' />
                    }
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Upload
                      {...props}
                      listType='picture-card'
                      fileList={toolImageFileList?.slice(-5)}
                      onPreview={handlePreview}
                      multiple={true}
                      accept='.png,.jpeg,.jpg'
                    >
                      {toolImageFileList?.length >= 5 ? null : (
                        <UploadButton isUploading={uploadingImage} />
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name='reasonCodesMke'
                    label={
                      <FormattedMessage id='warrantyClaim.reasonForReturn' />
                    }
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      allowClear
                      placeholder={intl.formatMessage({
                        id: 'general.pleaseSelect',
                      })}
                      defaultValue={[]}
                      onChange={(event) => setChosenReasonCodes(event)}
                    >
                      {reasonCodeOptions}
                    </Select>
                  </Form.Item>
                  {showMagnetSize && (
                    <Form.Item
                      name='magnetSize'
                      label={<FormattedMessage id='warrantyClaim.magnetSize' />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id='validation.required' />
                          ),
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={intl.formatMessage({
                          id: 'general.pleaseSelect',
                        })}
                        defaultValue={[]}
                      >
                        {magnetSizeOptions}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    name='reasonForReturnMke'
                    label={<FormattedMessage id='warrantyClaim.comments' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <TextArea autoComplete='off' rows={4} />
                  </Form.Item>

                  <HoneyPotFormItems />

                  <HighlightButton
                    type='primary'
                    htmlType='submit'
                    disabled={uploadingImage || uploadingProofOfPurchase}
                    loading={saving}
                  >
                    <FormattedMessage id='general.send' />
                  </HighlightButton>
                </Form>
                <JustInTimeNotice
                  textColor={justInTimeColorScheme}
                  buttonType={justiInTimeButtonType}
                />
              </Col>
            </Row>
          </Fade>
        </Typography>
      </Content>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt={<FormattedMessage id='general.previewUnavailable' />}
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </Container>
  );
};
