export const cdnHelper = {
  getHost: () => {
    let cdnHost;
    if (
      window.location.host === 'dev.stiletto.com' ||
      window.location.host === 'localhost:5001'
    ) {
      cdnHost = 'cdn-dev.stiletto.com';
    } else if (window.location.host === 'staging.stiletto.com') {
      cdnHost = 'cdn-staging.stiletto.com';
    } else {
      cdnHost = 'cdn.stiletto.com';
    }
    return cdnHost;
  },
};
