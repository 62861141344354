import createAxiosAction from '../createAxiosAction';
import {
  INSERT_WARRANTY_CLAIM,
  SUGGEST_PRODUCTS,
  UPLOAD_WARRANTY_CLAIM_FILE,
} from '../types';

export default {
  insertWarrantyClaim: (val) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/warrantyclaims`,
      body: { WarrantyClaim: val },
      startAction: INSERT_WARRANTY_CLAIM,
    });
  },
  uploadImage: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/warrantyclaims/image`,
      body: form,
      startAction: UPLOAD_WARRANTY_CLAIM_FILE,
      contentType: 'multipart/form-data',
    });
  },
  uploadDocFile: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/warrantyclaims/file`,
      body: form,
      startAction: UPLOAD_WARRANTY_CLAIM_FILE,
      contentType: 'multipart/form-data',
    });
  },
  suggestProducts: (query) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/suggestions',
      queryString: { query },
      startAction: SUGGEST_PRODUCTS,
    });
  },
};
