import { Input, Layout, Menu, Space } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

import Logo from '../../assets/logo.png';
import { useNavigationLinks } from '../../hooks';
import { Hamburger } from '../Hamburger';
import { SearchInput } from '../SearchInput';
import {
  Drawer,
  DrawerMenu,
  DrawerSubMenu,
  LogoWrapper,
  TopNavMenu,
  TopSubMenu,
} from './styled';
import { googleAnalyticsHelper } from '../../helpers';

const { Header } = Layout;
const { Search } = Input;

export const Navbar = () => {
  const history = useHistory();
  const intl = useIntl();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { productLinks, supportLinks } = useNavigationLinks();

  const handleSearch = (value) => {
    history.push(`/search?q=${value}`);
    setDrawerOpen(false);
  };

  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{ zIndex: 1, width: '100%', maxWidth: 1500, display: 'flex' }}
      >
        <LogoWrapper
          onClick={() => {
            googleAnalyticsHelper(
              'stiletto logo banner',
              'click',
              'stiletto logo'
            );
            history.push('/');
          }}
        >
          <img src={Logo} height={50} alt='logo' />
        </LogoWrapper>

        <TopNavMenu theme='dark' mode='horizontal'>
          <TopSubMenu
            title={
              <Space>
                {intl.formatMessage({ id: 'navigation.products' })}
                <DownOutlined />
              </Space>
            }
            onTitleClick={() => history.push('/search?cat=Striking Tools')}
          >
            {productLinks.map((link, index) => (
              <Menu.Item key={`navbar-products-${index}`}>
                {link.to ? (
                  <Link to={link.to}>{link.title}</Link>
                ) : (
                  <a href={link.href} target='_blank' rel='noopener noreferrer'>
                    {link.title}
                  </a>
                )}
              </Menu.Item>
            ))}
          </TopSubMenu>
          <TopSubMenu
            title={
              <Space>
                {intl.formatMessage({ id: 'navigation.support' })}
                <DownOutlined />
              </Space>
            }
            onTitleClick={() => history.push('/contact')}
          >
            {supportLinks.map((link, index) => (
              <Menu.Item key={`navbar-support-${index}`}>
                <Link to={link.to}>{link.title}</Link>
              </Menu.Item>
            ))}
          </TopSubMenu>
          <Menu.Item key='about'>
            <Link to='/about'>
              {intl.formatMessage({ id: 'navigation.about' })}
            </Link>
          </Menu.Item>
        </TopNavMenu>

        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <SearchInput />
        </div>

        <Hamburger
          onClick={() => setDrawerOpen(!drawerOpen)}
          open={drawerOpen}
        />

        <Drawer
          placement='right'
          closable={false}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
          style={{ marginTop: '64px' }}
        >
          <div style={{ padding: '1rem', backgroundColor: '#f6f6f6' }}>
            <Search
              style={{ border: '1px solid #b2b2b2' }}
              placeholder='Search product name or #...'
              onSearch={handleSearch}
            />
          </div>
          <DrawerMenu mode='inline' selectedKeys={[window.location.pathname]}>
            <DrawerSubMenu
              style={{ float: 'left' }}
              title={intl.formatMessage({ id: 'navigation.products' })}
            >
              {productLinks.map((link, index) => (
                <Menu.Item key={`navbar-products-${index}`}>
                  {link.to ? (
                    <Link to={link.to} onClick={() => setDrawerOpen(false)}>
                      {link.title}
                    </Link>
                  ) : (
                    <a
                      href={link.href}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {link.title}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </DrawerSubMenu>
            <DrawerSubMenu
              style={{ float: 'left' }}
              title={intl.formatMessage({ id: 'navigation.support' })}
            >
              {supportLinks.map((link, index) => (
                <Menu.Item key={`navbar-support-${index}`}>
                  <Link to={link.to} onClick={() => setDrawerOpen(false)}>
                    {link.title}
                  </Link>
                </Menu.Item>
              ))}
            </DrawerSubMenu>
            <Menu.Item key='/about'>
              <Link to='/about' onClick={() => setDrawerOpen(false)}>
                {intl.formatMessage({ id: 'navigation.about' })}
              </Link>
            </Menu.Item>
          </DrawerMenu>
        </Drawer>
      </div>
    </Header>
  );
};
