import styled from 'styled-components';

export const StyledImageZoom = styled.div`
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s ease;
  max-height: 500px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    transition: inherit;
  }

  &:hover::before {
    transform: scale(1.2);
  }
`;

export const Title = styled.div`
  color: white;
  text-transform: uppercase;
  font-size: 3em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
`;
