import { Col, Row, Space } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { ReactComponent as GlobeEurope } from '../../../assets/svgs/globe-europe-solid.svg';
import { TabLink } from '../../../components/TabLink';

export const StoreLinks = ({ theme }) => {
  const intl = useIntl();

  const pageLinks = [
    {
      icon: GlobeEurope,
      to: '/international-locations',
      label: intl.formatMessage({
        id: 'storeLocator.internationalLocationsLink',
      }),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {pageLinks.map((link, index) => {
        return (
          <Col key={`link-${index}`}>
            <Space direction='horizontal'>
              <Icon
                component={link.icon}
                style={{ color: theme === 'dark' ? 'white' : 'black' }}
              />
              <TabLink onClick={link.onClick} to={link.to} theme={theme}>
                {link.label}
              </TabLink>
            </Space>
          </Col>
        );
      })}
    </Row>
  );
};
