import { Form, Input, Row, Select, Typography, message, Checkbox } from 'antd';
import { Fade } from 'react-reveal';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { HighlightButton } from '../../../components';
import { newsletterActions } from '../../../store';
import { useDispatch } from 'react-redux';
import { withAppInsights, googleAnalyticsHelper } from '../../../helpers';
import {
  BackgroundImageContainer,
  NewsletterFooter,
  SignUpForm,
  SignUpFormDisclaimerText,
  SignUpFormRow,
  SignUpFormSubText,
  StyledFormItem,
} from './styled';
import { JustInTimeNotice } from '../../../components/JustInTimeNotice';

const { Title } = Typography;

export const NewsletterSignUpFooter = withAppInsights(() => {
  const intl = useIntl();
  const [newsletterForm] = Form.useForm();
  const dispatch = useDispatch();

  const [subscribeActionLoading, setSubscribeActionLoading] = useState(false);
  const fieldRequiredRules = { required: true, message: 'Required' };
  const justInTimeColorScheme = 'light';
  const justiInTimeButtonType = 'subscribeButton';

  const tradeOptions = [
    { value: 'Carpentry - Rough/Framing' },
    { value: 'Carpentry - Finish/Cabinetry' },
    { value: 'Civil Construction' },
    { value: 'Distribution/Dealer' },
    { value: 'DIY/Enthusiast' },
    { value: 'Drywall/Insulation/Paint' },
    { value: 'Electrical - Inside Wireman' },
    { value: 'Electrical - Residential Wireman' },
    { value: 'Electrical - Low Voltage/Datacom' },
    { value: 'Facility Maintenance' },
    { value: 'Farm/Agriculture' },
    { value: 'General Contractor' },
    { value: 'HVAC-R' },
    { value: 'Landscaping' },
    { value: 'Manufacturing' },
    { value: 'Masonry/Concrete' },
    { value: 'Mechanical Contractor' },
    { value: 'Metalworking' },
    { value: 'Millwright' },
    { value: 'Plumbing' },
    { value: 'Pole Barn Builder' },
    { value: 'Property Management' },
    { value: 'Remodeling' },
    { value: 'Pipe & Steamfitting' },
    { value: 'Roofing/Siding' },
    { value: 'Sheet Metal' },
    { value: 'Sprinkler & Fire Protection' },
    { value: 'Tile/Flooring' },
    { value: 'Utility - Water' },
    { value: 'Utility - Gas' },
    { value: 'Utility - Power' },
    { value: 'Utility - Telecom' },
    { value: 'Welding' },
    { value: 'Woodworking' },
  ];

  const validateHoneypot = (honeypotField) => {
    if (typeof honeypotField !== 'undefined') {
      return false;
    }
    return true;
  };

  const handleSuccess = () => {
    message.success(
      intl.formatMessage({ id: 'footer.newsletter.subscribeSuccess' })
    );
    setSubscribeActionLoading(false);
    newsletterForm.resetFields();
  };

  const handleFailure = () => {
    message.error(
      intl.formatMessage({ id: 'footer.newsletter.subscribeFail' })
    );
    setSubscribeActionLoading(false);
  };

  const submitBrazeForm = async (form) => {
    setSubscribeActionLoading(true);
    if (validateHoneypot(form.emailAddress)) {
      googleAnalyticsHelper('form submit', 'button click', 'newsletter signup');
      try {
        await dispatch(newsletterActions.insertNewsletterSignup(form));
        handleSuccess();
      } catch (error) {
        handleFailure();
      }
    }
  };

  return (
    <Fade cascade delay={100}>
      <BackgroundImageContainer>
        <NewsletterFooter>
          <Title style={{ color: '#ffff', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'footer.newsletter.title' })}
          </Title>
          <SignUpFormSubText>
            {intl.formatMessage({ id: 'footer.newsletter.subtitle' })}
          </SignUpFormSubText>
          <SignUpForm
            form={newsletterForm}
            onFinish={submitBrazeForm}
            layout='vertical'
          >
            <SignUpFormRow>
              <Form.Item
                name='emailAddress'
                className='ohnohoney'
                label='email'
              >
                <Input />
              </Form.Item>
              <StyledFormItem
                name='firstName'
                label={intl.formatMessage({
                  id: 'footer.newsletter.firstName',
                })}
                rules={[fieldRequiredRules]}
                style={{ width: '48%' }}
              >
                <Input />
              </StyledFormItem>
              <StyledFormItem
                name='lastName'
                label={intl.formatMessage({ id: 'footer.newsletter.lastName' })}
                rules={[fieldRequiredRules]}
                style={{ width: '48%' }}
              >
                <Input />
              </StyledFormItem>
            </SignUpFormRow>
            <SignUpFormRow>
              <StyledFormItem
                name='emailMke'
                label={intl.formatMessage({ id: 'footer.newsletter.email' })}
                rules={[
                  fieldRequiredRules,
                  {
                    type: 'email',
                    message: intl.formatMessage({
                      id: 'validation.invalidEmail',
                    }),
                  },
                ]}
                style={{ width: '48%' }}
              >
                <Input name='email' inputMode='email' />
              </StyledFormItem>
              <StyledFormItem
                name='trade'
                label={intl.formatMessage({ id: 'footer.newsletter.trade' })}
                rules={[fieldRequiredRules]}
                style={{ width: '30%' }}
              >
                <Select
                  placeholder='Select a Trade'
                  size='large'
                  options={tradeOptions}
                />
              </StyledFormItem>
              <StyledFormItem
                name='zip'
                label={intl.formatMessage({ id: 'footer.newsletter.zip' })}
                rules={[fieldRequiredRules]}
                style={{ width: '15%' }}
              >
                <Input />
              </StyledFormItem>
            </SignUpFormRow>
            <StyledFormItem
              name='check'
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: 'boolean',
                  message: intl.formatMessage({ id: 'validation.required' }),
                },
              ]}
              labelAlign='left'
              valuePropName='checked'
            >
              <Checkbox style={{ color: 'white' }}>
                {intl.formatMessage({ id: 'footer.newsletter.disclaimer' })}
              </Checkbox>
            </StyledFormItem>
            <Row style={{ justifyContent: 'center' }}>
              <HighlightButton
                htmlType='submit'
                disabled={subscribeActionLoading}
                style={{ width: '15rem' }}
              >
                {intl.formatMessage({
                  id: 'footer.subscribe',
                })}
              </HighlightButton>
            </Row>
          </SignUpForm>
          <SignUpFormDisclaimerText>
            {intl.formatMessage({ id: 'footer.newsletter.unsubscribe' })}
          </SignUpFormDisclaimerText>
          <div style={{ width: '60%' }}>
            <JustInTimeNotice
              textColor={justInTimeColorScheme}
              buttonType={justiInTimeButtonType}
            />
          </div>
        </NewsletterFooter>
      </BackgroundImageContainer>
    </Fade>
  );
});
