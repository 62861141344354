import createAxiosAction from '../createAxiosAction';
import {
  GET_PRODUCT,
  SEARCHING_PRODUCTS,
  SET_PRODUCT_DETAILS,
  SET_SEARCH_FILTERS_SIDER,
} from '../types';

export default {
  getProduct: (sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}`,
      startAction: GET_PRODUCT,
    });
  },
  searchProducts: (queryString) => {
    return createAxiosAction({
      url: '/api/v1/products/search',
      queryString,
      startAction: SEARCHING_PRODUCTS,
    });
  },
  setProductDetails: (product) => (dispatch) => {
    dispatch({ type: SET_PRODUCT_DETAILS, product });
  },
  setSearchFiltersSider: (collapsed) => (dispatch) => {
    dispatch({ type: SET_SEARCH_FILTERS_SIDER, collapsed });
  },
};
