import React from 'react';
import { StyledImageZoom, Title } from './styled';

export const ImageZoom = ({ image, title }) => {
  return (
    <StyledImageZoom image={image}>
      <Title>{title}</Title>
      <img src={image} style={{ visibility: 'hidden' }} alt={title} />
    </StyledImageZoom>
  );
};
