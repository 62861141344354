import createAxiosAction from '../createAxiosAction';
import { GET_REASON_CODES } from '../types';

export default {
  getReasonCodes: () => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/reasonCodes',
      startAction: GET_REASON_CODES,
    });
  },
};
