import { Layout, Space } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from '../../components/Container';

const { Footer, Content } = Layout;

export const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MegaFooter = styled.div`
  color: white;
  background-color: black;
  width: 100%;
  display: flex;
`;

export const FooterButton = styled.button`
  background: none;
  border-style: none;
  color: white;
  padding: 0px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const FooterMenu = styled.div`
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #2b2b2b;
  font-size: 14px;
  line-height: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLink = styled(Link)`
  color: white;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

export const FooterExternalLink = styled.a`
  color: white;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

export const FooterAction = styled.span`
  color: white;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: white;
  }
`;

export const FooterSpace = styled(Space)`
  text-align: center;

  @media (max-width: 480px) {
    padding-top: 20px;
  }
`;

export const StyledFooterSection = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  > span {
    font-family: 'Neue Helvetica W05', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0.3rem;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li a {
      white-space: nowrap;
    }
  }
`;

export const FooterContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const gradientBackground =
  'linear-gradient(to bottom, transparent 0%, transparent 57%, #cfcfcf 100%);';
const darkBackground = '#2b2b2b';
const lightBackground = 'white';

export const MainContent = styled(Content)`
  margin-top: 64px;

  background: ${(props) => {
    switch (props?.theme) {
      case 'dark':
        return darkBackground;
      case 'light-gradient':
        return gradientBackground;
      default:
        return lightBackground;
    }
  }};

  // IE Only
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: calc(100vh - 290px);
  }
`;

export const PrivacyImage = styled.img`
  width: 2rem;
  padding-left: 3px;
`;
