import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';

import {LOCALES_INTL_LOCALE} from './utils';

const mapStateToProps = ({locales: {locale, messages}}) => ({
  key: LOCALES_INTL_LOCALE[locale],
  locale,
  messages
});

export default connect(mapStateToProps)(IntlProvider);