import { Layout, Skeleton, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { StringParam, useQueryParams } from 'use-query-params';

import { Container } from '../../../components/Container';
import { pageContentsActions } from '../../../store';
import { useIntl } from 'react-intl';

const { Content } = Layout;

export const FAQ = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const content = useSelector((state) => state.pageContents.content?.faq);
  const [queryParams] = useQueryParams({
    version: StringParam,
  });

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content?.body)
      dispatch(
        pageContentsActions.getPageContent({
          ...queryParams,
          dataKey: 'faq',
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  return (
    <Container style={{ maxWidth: 700, padding: 0 }}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'faq.helmetTitle' })}</title>
      </Helmet>
      <Content>
        <Typography>
          <Fade delay={150}>
            {content?.body ? (
              <ReactMarkdown source={content.body} />
            ) : (
              <Skeleton active paragraph={{ rows: 10 }} />
            )}
          </Fade>
        </Typography>
      </Content>
    </Container>
  );
};
