import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';

import { SearchBox, SearchButton, SearchForm, SearchFormItem } from './styled';
import { useIntl } from 'react-intl';
import { googleAnalyticsHelper } from '../../helpers';

export const SearchInput = () => {
  const history = useHistory();
  const intl = useIntl();
  const [searchTerm] = useQueryParam('q', StringParam);
  const [value, setValue] = useState(searchTerm || '');

  const handleSubmit = () => {
    googleAnalyticsHelper('search', 'product search', value);

    history.push(`/search?q=${value}`);
  };

  return (
    <SearchForm name='search' onFinish={handleSubmit}>
      <SearchFormItem
        label={intl.formatMessage({ id: 'navigation.searchLabel' })}
      >
        <SearchBox
          onChange={(e) => setValue(e.target.value)}
          id='search-box'
          placeholder={intl.formatMessage({
            id: 'navigation.searchPlaceholder',
          })}
        />
        <label htmlFor='search-box'>
          <SearchButton onClick={handleSubmit} className='search-icon' />
        </label>
      </SearchFormItem>
    </SearchForm>
  );
};
