import translation from '../../locales/messages';
import { DEFAULT_LOCALE, flattenMessages } from '../../locales/utils';
import { UPDATE_LOCALE } from '../types';
import { LOCALE_STORAGE_KEY, setSiteLocale } from './helpers';

export const generateInitialState = () => {
  const browserLanguage = navigator.language.split('-')[0];
  const localeFromStorage = localStorage.getItem(LOCALE_STORAGE_KEY);

  let locale;
  let messages;
  let languages;

  if (window.translations) {
    languages = Object.keys(window.translations);

    if (localeFromStorage && window.translations[localeFromStorage]) {
      locale = localeFromStorage;
      messages = window.translations[localeFromStorage];
    } else if (window.translations[browserLanguage]) {
      locale = browserLanguage;
      messages = window.translations[browserLanguage];
    } else {
      locale = DEFAULT_LOCALE;
      messages = flattenMessages(translation[DEFAULT_LOCALE]);
    }
  } else {
    languages = ['en'];
    locale = DEFAULT_LOCALE;
    messages = flattenMessages(translation[DEFAULT_LOCALE]);
  }

  return {
    locale,
    messages,
    languages,
  };
};

//breaking the generation of the initial state into it's own method so we can unit test it better.
export const initialState = generateInitialState();

export default (state, action) => {
  state = state || initialState;

  if (action.type === UPDATE_LOCALE) {
    setSiteLocale(action.locale);
    return {
      ...state,
      locale:
        window.translations && window.translations[action.locale]
          ? action.locale
          : DEFAULT_LOCALE,
      messages: window.translations
        ? window.translations[action.locale] ||
          window.translations[DEFAULT_LOCALE]
        : flattenMessages(translation[DEFAULT_LOCALE]),
    };
  } else {
    return state;
  }
};
