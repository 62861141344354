import createAxiosAction from '../createAxiosAction';
import { GET_INTERNATIONAL_LOCATIONS } from '../types';

export default {
  getInternationalLocations: () => {
    return createAxiosAction({
      url: `/api/v1/retailstores/international`,
      startAction: GET_INTERNATIONAL_LOCATIONS,
    });
  },
};
