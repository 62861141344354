import { Typography } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const { Text } = Typography;

export const Container = styled.div`
  margin-top: 0.5rem;
`;

export const DisclaimerText = styled(Text)`
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: small;
`;

export const InternalLink = styled(Link)`
  color: ${(props) => props.textColor} !important;
  text-decoration: underline !important;

  &:hover {
    color: ${(props) => props.textColor};
  }
`;

export const ExternalLink = styled.a`
  color: ${(props) => props.textColor} !important;
  text-decoration: underline !important;

  &:hover {
    color: ${(props) => props.textColor};
  }
`;
