import { FilterOutlined } from '@ant-design/icons';
import {
  Col,
  Divider,
  Empty,
  Layout,
  PageHeader,
  Pagination,
  Row,
  Space,
  Spin,
} from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';

import {
  Container,
  ProductSearchResult,
  SearchFiltersSider,
} from '../../components';
import { productsActions } from '../../store';
import { FiltersButton } from './styled';

const { Content } = Layout;

export const ProductSearch = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [query] = useQueryParam('q', StringParam);
  const [handleMaterial] = useQueryParam('hm', ArrayParam);
  const [handleType] = useQueryParam('ht', ArrayParam);
  const [category] = useQueryParam('cat', ArrayParam);
  const [headWeight] = useQueryParam('hw', ArrayParam);
  const [page, setPage] = useQueryParam('p', withDefault(NumberParam, 1));
  const pageSize = 16;

  // app state
  const searchResults = useSelector((state) => state.products.searchResults);
  const isSearching = useSelector(
    (state) => state.products.searchResultsLoading
  );
  const searchFiltersCollapsed = useSelector(
    (state) => state.products.searchFiltersSiderCollapsed
  );

  /***
   * When the search term, any filter, or page number changes, do another product search
   */
  useEffect(() => {
    dispatch(
      productsActions.searchProducts({
        query,
        handleMaterial,
        handleType,
        category,
        headWeight,
        page,
      })
    );
  }, [dispatch, query, handleMaterial, handleType, category, headWeight, page]);

  /***
   * When the page changes, scroll back to the top
   */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  /***
   * When any filter changes, reset the page to 1
   */
  useEffect(() => {
    if (page !== 1) setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMaterial, handleType, category, headWeight]);

  const products = searchResults?.data.map((product, index) => {
    return (
      <Col
        key={`search-result-${index}`}
        xs={24}
        sm={12}
        md={12}
        lg={12}
        xl={8}
        xxl={6}
      >
        <Fade delay={index * 50}>
          <ProductSearchResult product={product} />
        </Fade>
      </Col>
    );
  });

  return (
    <Container>
      <Helmet>
        <title>{intl.formatMessage({ id: 'productSearch.titleHelmet' })}</title>
      </Helmet>
      <PageHeader
        style={{ padding: 0 }}
        title={<FormattedMessage id='productSearch.title' />}
      />
      <Divider />
      <div style={{ display: 'flex' }}>
        <FiltersButton
          icon={<FilterOutlined />}
          onClick={() =>
            dispatch(
              productsActions.setSearchFiltersSider(!searchFiltersCollapsed)
            )
          }
        />
        {searchResults && searchResults.totalCount > 0 && (
          <Fade>
            <Space direction='horizontal' style={{ paddingBottom: '1rem' }}>
              <strong>
                <FormattedMessage
                  id='productSearch.resultsLabel'
                  values={{
                    query,
                  }}
                />
              </strong>
              <strong>
                <small>
                  <FormattedMessage
                    id='productSearch.resultsCount'
                    values={{
                      startIndex: (page - 1) * pageSize + 1,
                      endIndex: (page - 1) * pageSize + searchResults.count,
                      totalResults: searchResults.totalCount,
                      query,
                    }}
                  />
                </small>
              </strong>
            </Space>
          </Fade>
        )}
      </div>
      <Layout>
        <SearchFiltersSider />
        <Content style={{ overflow: 'hidden' }}>
          <Spin
            size='large'
            wrapperClassName='height-100vh'
            spinning={isSearching}
          >
            <Row gutter={[16, 20]}>{products}</Row>
            {products?.length === 0 && (
              <Row
                style={{ minHeight: '500px' }}
                justify='center'
                align='middle'
              >
                <Col>
                  <Empty
                    description={
                      <FormattedMessage id='productSearch.noProductsFound' />
                    }
                  />
                </Col>
              </Row>
            )}
          </Spin>
          {searchResults?.data.length > 0 && (
            <Row style={{ marginTop: '1rem' }} justify='center'>
              <Col>
                <Pagination
                  current={page || 1}
                  pageSize={16}
                  total={searchResults.totalCount}
                  onChange={(page) => setPage(page, 'pushIn')}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          )}
        </Content>
      </Layout>
    </Container>
  );
};
