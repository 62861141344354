import { Menu } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useFeatureFlags } from '../../hooks';
import { productsActions } from '../../store';
import { FilterItem } from './components/FilterItem';
import { StyledMenu, StyledSider } from './styled';

const { SubMenu } = Menu;

export const SearchFiltersSider = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const collapsed = useSelector(
    (state) => state.products.searchFiltersSiderCollapsed
  );

  const { searchFiltersWithReplacementsGroup } = useFeatureFlags();
  const { squaresProductLaunch } = useFeatureFlags();

  // all the query params
  const [category, setCategory] = useQueryParam(
    'cat',
    withDefault(ArrayParam, [])
  );
  const [headWeight, setHeadWeight] = useQueryParam(
    'hw',
    withDefault(ArrayParam, [])
  );
  const [handleMaterial, setHandleMaterial] = useQueryParam(
    'hm',
    withDefault(ArrayParam, [])
  );
  const [handleType, setHandleType] = useQueryParam(
    'ht',
    withDefault(ArrayParam, [])
  );

  /***
   * updateHandleType will add the handle type to the query params
   * if it is checked, and remove it it unchecked
   * @param checked: if the filter is checked
   * @param value: the value of the checkbox
   */
  const updateHandleType = (checked, value) => {
    if (!checked) setHandleType(handleType.filter((type) => type !== value));
    else setHandleType([...handleType, value]);
  };

  /***
   * updateHandleMaterial will add the handle material to the query params
   * if it is checked, and remove it it unchecked
   * @param checked: if the filter is checked
   * @param value: the value of the checkbox
   */
  const updateHandleMaterial = (checked, value) => {
    if (!checked)
      setHandleMaterial(
        handleMaterial.filter((material) => material !== value)
      );
    else setHandleMaterial([...handleMaterial, value]);
  };

  /***
   * updateHeadWeight will add the head weight to the query params
   * if it is checked, and remove it it unchecked
   * @param checked: if the filter is checked
   * @param value: the value of the checkbox
   */
  const updateHeadWeight = (checked, value) => {
    if (!checked)
      setHeadWeight(headWeight.filter((weight) => weight !== value));
    else setHeadWeight([...headWeight, value]);
  };

  /***
   * updateCategory will add the category to the query params
   * if it is checked, and remove it it unchecked
   * @param checked: if the filter is checked
   * @param value: the value of the checkbox
   */
  const updateCategory = (checked, value) => {
    if (!checked) setCategory(category.filter((cat) => cat !== value));
    else setCategory([...category, value]);
  };

  const squaresFilterMenuItem = (
    <Menu.Item>
      <FilterItem
        queryParam={category}
        updateFunction={updateCategory}
        value='Squares'
        label={intl.formatMessage({
          id: 'productSearch.filterSquares',
        })}
      />
    </Menu.Item>
  );

  const filtersWithReplacementGroup = (
    <StyledMenu
      mode='inline'
      defaultSelectedKeys={['1']}
      defaultOpenKeys={[
        'category',
        'handleType',
        'handleMaterial',
        'headWeight',
        'replacements',
      ]}
      selectable={false}
    >
      <SubMenu
        key='category'
        title={intl.formatMessage({ id: 'searchFilters.category' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Striking Tools'
            label={intl.formatMessage({
              id: 'productSearch.filterStrikingTools',
            })}
          />
        </Menu.Item>
        {/* TODO: Remove feature flag once squares launch */}
        {squaresProductLaunch && squaresFilterMenuItem}
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Pry Bars'
            label={intl.formatMessage({ id: 'productSearch.filterPryBars' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Accessories'
            label={intl.formatMessage({
              id: 'productSearch.filterAccessories',
            })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='replacements'
        title={intl.formatMessage({ id: 'searchFilters.replacements' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Faces'
            label={intl.formatMessage({
              id: 'productSearch.filterFaces',
            })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Handles'
            label={intl.formatMessage({ id: 'productSearch.filterHandles' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Grips'
            label={intl.formatMessage({
              id: 'productSearch.filterGrips',
            })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='handleType'
        title={intl.formatMessage({ id: 'searchFilters.handleType' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Straight'
            label={intl.formatMessage({ id: 'productSearch.filterStraight' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Curved'
            label={intl.formatMessage({ id: 'productSearch.filterCurved' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Hybrid'
            label={intl.formatMessage({ id: 'productSearch.filterHybrid' })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='handleMaterial'
        title={intl.formatMessage({ id: 'searchFilters.handleMaterial' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Hickory'
            label={intl.formatMessage({ id: 'productSearch.filterHickory' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Fiberglass'
            label={intl.formatMessage({
              id: 'productSearch.filterFiberglass',
            })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Titanium'
            label={intl.formatMessage({ id: 'productSearch.filterTitanium' })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='headWeight'
        title={intl.formatMessage({ id: 'searchFilters.headWeight' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='9'
            label='9oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='10'
            label='10oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='12'
            label='12oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='14'
            label='14oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='15'
            label='15oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='16'
            label='16oz'
          />
        </Menu.Item>
      </SubMenu>
    </StyledMenu>
  );

  // Remove this and the corresponding flag when the new filters are solid in prod.
  const filtersWithoutReplacementGroup = (
    <StyledMenu
      mode='inline'
      defaultSelectedKeys={['1']}
      defaultOpenKeys={[
        'category',
        'handleType',
        'handleMaterial',
        'headWeight',
        'replacements',
      ]}
      selectable={false}
    >
      <SubMenu
        key='category'
        title={intl.formatMessage({ id: 'searchFilters.category' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Striking Tools'
            label={intl.formatMessage({
              id: 'productSearch.filterStrikingTools',
            })}
          />
        </Menu.Item>
        {/* TODO: Remove feature flag once squares launch */}
        {squaresProductLaunch && squaresFilterMenuItem}
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Pry Bars'
            label={intl.formatMessage({ id: 'productSearch.filterPryBars' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Accessories'
            label={intl.formatMessage({
              id: 'productSearch.filterAccessories',
            })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={category}
            updateFunction={updateCategory}
            value='Replacement Parts'
            label={intl.formatMessage({
              id: 'productSearch.filterReplacements',
            })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='handleType'
        title={intl.formatMessage({ id: 'searchFilters.handleType' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Straight'
            label={intl.formatMessage({ id: 'productSearch.filterStraight' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Curved'
            label={intl.formatMessage({ id: 'productSearch.filterCurved' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleType}
            updateFunction={updateHandleType}
            value='Hybrid'
            label={intl.formatMessage({ id: 'productSearch.filterHybrid' })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='handleMaterial'
        title={intl.formatMessage({ id: 'searchFilters.handleMaterial' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Hickory'
            label={intl.formatMessage({ id: 'productSearch.filterHickory' })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Fiberglass'
            label={intl.formatMessage({
              id: 'productSearch.filterFiberglass',
            })}
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={handleMaterial}
            updateFunction={updateHandleMaterial}
            value='Titanium'
            label={intl.formatMessage({ id: 'productSearch.filterTitanium' })}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='headWeight'
        title={intl.formatMessage({ id: 'searchFilters.headWeight' })}
      >
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='9'
            label='9oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='10'
            label='10oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='12'
            label='12oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='14'
            label='14oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='15'
            label='15oz'
          />
        </Menu.Item>
        <Menu.Item>
          <FilterItem
            queryParam={headWeight}
            updateFunction={updateHeadWeight}
            value='16'
            label='16oz'
          />
        </Menu.Item>
      </SubMenu>
    </StyledMenu>
  );

  return (
    <StyledSider
      collapsed={collapsed}
      breakpoint='md'
      collapsedWidth='0'
      onCollapse={(isCollapsed) =>
        dispatch(productsActions.setSearchFiltersSider(isCollapsed))
      }
      trigger={null}
    >
      {searchFiltersWithReplacementsGroup
        ? filtersWithReplacementGroup
        : filtersWithoutReplacementGroup}
    </StyledSider>
  );
};
