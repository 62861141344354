import { Layout, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';

import { useIntl } from 'react-intl';

const { Title } = Typography;
const { Content } = Layout;

export const PrivacyPolicy = () => {
  const intl = useIntl();
  const [oneTrustScriptLoaded, setOneTrustScriptLoaded] = useState(false);

  useEffect(() => {
    loadOneTrustScript();
  });

  useEffect(() => {
    if (oneTrustScriptLoaded) {
      getOneTrustPrivacyPolicy();
    }
  }, [oneTrustScriptLoaded]);

  const loadOneTrustScript = () => {
    const script = document.createElement('script');
    script.id = 'otprivacy-notice-script';
    script.src =
      'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    script.addEventListener('load', () => setOneTrustScriptLoaded(true));
    document.body.appendChild(script);
  };

  const getOneTrustPrivacyPolicy = () => {
    window.OneTrust.NoticeApi.Initialized.then(function () {
      window.OneTrust.NoticeApi.LoadNotices([
        'https://privacyportal-cdn.onetrust.com/0bcd3d0d-df9d-4291-9bd5-f276c7dae8aa/privacy-notices/efd68c2a-d4fa-4c47-8e76-e82a3ae6f4a6.json',
      ]);
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'policies.privacyPolicy.title' })}
        </title>
      </Helmet>
      <Content>
        <Typography>
          <Fade delay={150}>
            <Title level={2}>
              {intl.formatMessage({ id: 'policies.privacyPolicy.subTitle' })}
            </Title>
            <div
              style={{ marginLeft: '-1rem' }}
              id='otnotice-efd68c2a-d4fa-4c47-8e76-e82a3ae6f4a6'
              className='otnotice'
            ></div>
          </Fade>
        </Typography>
      </Content>
    </React.Fragment>
  );
};
