import { Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';
import { productHelper } from '../../helpers';
import { productsActions } from '../../store';

import {
  ImageWrapper,
  ProductCard,
  ProductDetails,
  ProductImage,
} from './styled';

const { Text } = Typography;

export const ProductSearchResult = ({ product }) => {
  const dispatch = useDispatch();

  return (
    <Link
      to={`/p/${productHelper.titleToSlug(product.title)}/${product.skuAlias}`}
      onClick={() => dispatch(productsActions.setProductDetails(product))}
    >
      <ProductCard>
        <ImageWrapper>
          {product.imageUrls?.length > 0 ? (
            <ProductImage src={product.imageUrls[0]} alt={product.skuAlias} />
          ) : (
            <Logo width={300} style={{ color: '#ccc' }} />
          )}
        </ImageWrapper>
        <ProductDetails>
          <Text style={{ color: '#305EA4' }}>{product.skuAlias}</Text>
          <Text strong style={{ color: '#305EA4' }}>
            {product.title}
          </Text>
        </ProductDetails>
      </ProductCard>
    </Link>
  );
};
