import { Divider, PageHeader } from 'antd';
import React from 'react';

import { Container, TabLink } from '../../../components';
import { SiteLayout } from '../../SiteLayout';
import { useIntl } from 'react-intl';

export const PolicyLayout = ({ children }) => {
  const intl = useIntl();
  return (
    <SiteLayout>
      <Container style={{ paddingBottom: 0 }}>
        <PageHeader
          style={{ padding: 0 }}
          title={intl.formatMessage({ id: 'policies.policiesTitle' })}
          extra={[
            <TabLink key='legal' to='/legal'>
              {intl.formatMessage({ id: 'policies.legalTab' })}
            </TabLink>,
            <TabLink key='stores' to='/privacy-policy'>
              {intl.formatMessage({ id: 'policies.privacyPolicyTab' })}
            </TabLink>,
          ]}
        />
        <Divider />
      </Container>
      <Container style={{ maxWidth: 700, paddingTop: 0 }}>{children}</Container>
    </SiteLayout>
  );
};
