import { useIntl } from 'react-intl';
import useFeatureFlags from './useFeatureFlags';

const useNavigationLinks = () => {
  const intl = useIntl();
  const { squaresProductLaunch } = useFeatureFlags();

  const productLinks = squaresProductLaunch
    ? [
        {
          title: intl.formatMessage({ id: 'navigation.strikingTools' }),
          to: '/category/striking-tools',
        },
        {
          title: intl.formatMessage({ id: 'navigation.squares' }),
          to: '/category/squares',
        },
        {
          title: intl.formatMessage({ id: 'navigation.pryBars' }),
          to: '/category/pry-bars',
        },
        {
          title: intl.formatMessage({ id: 'navigation.accessories' }),
          to: '/category/accessories',
        },
        {
          title: intl.formatMessage({ id: 'navigation.gear' }),
          href: 'https://www.stilettotoolswag.com/',
        },
      ]
    : [
        {
          title: intl.formatMessage({ id: 'navigation.strikingTools' }),
          to: '/category/striking-tools',
        },
        {
          title: intl.formatMessage({ id: 'navigation.pryBars' }),
          to: '/category/pry-bars',
        },
        {
          title: intl.formatMessage({ id: 'navigation.accessories' }),
          to: '/category/accessories',
        },
        {
          title: intl.formatMessage({ id: 'navigation.gear' }),
          href: 'https://www.stilettotoolswag.com/',
        },
      ];

  const whereToBuyLinks = [
    {
      title: intl.formatMessage({ id: 'navigation.internationalLocations' }),
      to: '/international-locations',
    },
  ];

  const supportLinks = [
    {
      title: intl.formatMessage({ id: 'navigation.faq' }),
      to: '/faq',
    },
    {
      title: intl.formatMessage({ id: 'navigation.warrantyRequest' }),
      to: '/warranty',
    },
    {
      title: intl.formatMessage({ id: 'navigation.contactUs' }),
      to: '/contact',
    },
  ];

  return { productLinks, whereToBuyLinks, supportLinks };
};

export default useNavigationLinks;
