import createAxiosAction from '../createAxiosAction';
import { GET_PAGE_CONTENT } from '../types';

export default {
  getPageContent: (queryString) => {
    return createAxiosAction({
      url: `/api/v1/pagecontents`,
      startAction: GET_PAGE_CONTENT,
      queryString,
    });
  },
};
