export default {
  general: {
    yes: 'Yes',
    no: 'No',
    notApplicable: 'N/A',
    delete: 'Delete',
    genericErrorMessage: 'Something went wrong. Please try again.',
    genericSuccessSubmitMessage: 'Successfully submitted',
    stilettoWebsite: 'www.stiletto.com',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    phoneNumber: 'Phone Number',
    email: 'Email',
    emailAddress: 'Email Address',
    city: 'City',
    state: 'State',
    stateProvince: 'State/Province',
    country: 'Country',
    countryRegion: 'Country/Region',
    region: 'Region',
    company: 'Company',
    addressLine1: 'Address 1',
    addressLine2: 'Address 2',
    zipPostalCode: 'Zip/Postal Code',
    pleaseSelect: 'Please Select',
    message: 'Message',
    send: 'Send',
    uploadPending: 'Uploading...',
    upload: 'Upload',
    previewUnavailable: 'Preview Unavailable',
  },
  placeholder: {
    selectACountry: 'Select a Country',
  },
  socialMedia: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
  },
  validation: {
    invalidEmail: 'Invalid Email',
    invalidPhoneNumber: 'Invalid Phone Number',
    required: 'Required',
    fileSizeUnder20MB: 'File cannot be larger than 20 MB',
    unsupportedFileType: 'Unsupported File Type',
    errorUploadingFile: 'An error occurred while uploading',
    successUploadingFile: 'Successfully uploaded file',
  },
  footer: {
    legal: 'Legal',
    safetyNotices: 'Safety Notices',
    privacy: 'Privacy Notice',
    contactUs: 'Contact Us',
    copyright: '©{year} by Stiletto Tools. All rights reserved.',
    signIn: 'Sign In',
    catalog: 'Catalog',
    social: 'Social',
    subscribeToNewsletter: 'Newsletter',
    signup: 'Sign Up',
    subscribe: 'Subscribe',
    emailPlaceholder: 'Enter Email',
    cookiePreferences: 'Cookie Preferences',
    privacyChoices: 'Your Privacy Choices',
    subscribeSuccess: 'Successfully subscribed!',
    subscribeFail: 'An error has occurred. Please contact customer support.',
    consentText:
      'Yes, please keep me updated on Stiletto products, special offers  and events',
    newsletter: {
      title: 'SIGN UP FOR EMAIL',
      subtitle:
        'Be the first to know about new products and more from STILETTO®.',
      disclaimer: 'Join STILETTO® to receive updates on new products and more.',
      unsubscribe: 'You may unsubscribe at any time.',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      trade: 'Trade',
      zip: 'Zip',
      subscribeSuccess: 'Successfully subscribed!',
      subscribeFail: 'An error has occurred. Please contact customer support.',
    },
  },
  navigation: {
    products: 'Products',
    strikingTools: 'Striking Tools',
    squares: 'Squares',
    pryBars: 'Pry Bars',
    accessories: 'Accessories',
    replacements: 'Replacements',
    gear: 'Gear',
    whereToBuy: 'Where To Buy',
    online: 'Online',
    findAStore: 'Find a Store',
    support: 'Support',
    contactUs: 'Contact Us',
    warrantyRequest: 'Warranty Request',
    about: 'About Us',
    stilettoStory: 'Stiletto Story',
    internationalLocations: 'International Distributors',
    searchPlaceholder: 'Product Name or #',
    searchLabel: 'Product Search',
    faq: 'FAQ',
  },
  productDetails: {
    title: 'Products',
    whereToBuyButtonLabel: 'Buy Now',
    featuresLabel: 'Features:',
    specificationsLabel: 'Specifications',
    relatedProductsLabel: 'Related Products',
    youMightAlsoLikeLabel: 'You might also like',
    strikingTools: 'Striking Tools',
    squares: 'Squares',
    pryBars: 'Pry Bars',
    accessories: 'Accessories',
    replacements: 'Replacements',
    warrantyCertifications: 'Warranty / Certifications',
    warrantyPolicy: 'Warranty',

    dimensions: {
      dimensionsLabel: 'Dimensions',
      depthInches: 'Depth',
      lengthInches: 'Length',
      totalWeightOunces: 'Total Weight',
      weightOfHeadOunces: 'Weight of Head',
      widthInches: 'Width',
      heightInches: 'Height',
    },
    metadata: {
      specificationDetailsLabel: 'Details',
      handleType: 'Handle Type',
      colorFamily: 'Color Family',
      hammerType: 'Claw Type',
      handToolType: 'Hand Tool Type',
      handleMaterial: 'Handle Material',
      materialType: 'Material Type',
      replaceableHead: 'Replaceable Face',
      face: 'Face',
      sidingOrExtTrim: 'Siding or Ext Trim',
      remodeling: 'Remodeling',
      framing: 'Framing',
      poleBarn: 'Pole Barn',
      concrete: 'Concrete',
      scaffolding: 'Scaffolding',
      finish: 'Finish',
      drywall: 'Drywall',
      roofing: 'Roofing',
      wireLathe: 'Wire Lathe',
      numberOfNailSlots: 'Number of Nail Slots',
    },
    validation: {
      productNotFound: 'Product not found',
    },
  },
  adminProductDetails: {
    title: 'Edit Product | Stiletto Tools',
    editProduct: 'Edit Product',
    saveBtn: 'Save',
    finish: 'Finish',
    editImageOrder: 'Edit Image Order',
    sku: 'Sku',
    productTitle: 'Title',
    overview: 'Overview',
    featureIsRequired: 'Feature is Required',
    addFeature: 'Add Feature',
    categories: 'Categories',
    isFeatured: 'Is Featured',
    isDiscontinued: 'Is Discontinued',
    uploadBtn: 'Upload',
    unsupportedFileTypeMessage: 'Unsupported file type',
  },
  productSearch: {
    titleHelmet: 'Search | Stiletto Tools',
    title: 'Products',
    breadcrumbHome: 'Home',
    breadcrumbSearch: 'Search',
    breadcrumbBrowse: 'Browse',
    resultsCount: '{startIndex}-{endIndex} of {totalResults} Results',
    resultsLabel: 'Results: "{query}"',
    noProductsFound: 'No products matched your search criteria',
    filterStrikingTools: 'Striking Tools',
    filterSquares: 'Squares',
    filterPryBars: 'Pry Bars',
    filterAccessories: 'Accessories',
    filterReplacements: 'Replacements',
    filterStraight: 'Straight',
    filterCurved: 'Curved',
    filterHybrid: 'Hybrid',
    filterHickory: 'Hickory',
    filterFiberglass: 'Fiberglass',
    filterRubber: 'Rubber',
    filterComposite: 'Composite',
    filterTitanium: 'Titanium',
    filterFaces: 'Faces',
    filterHandles: 'Handles',
    filterGrips: 'Grips',
  },
  internationalLocations: {
    title: 'Where To Buy - International | Stiletto Tools',
  },
  storeLocator: {
    title: 'Where To Buy - Store Locator | Stiletto Tools',
    internationalLocationsLink: 'European Locations',
  },
  aboutUs: {
    title: 'About Us',
  },
  contactUs: {
    title: 'Contact Us | Stiletto Tools',
    contactUsText: 'Contact Us',
    mailShipping: 'Mail / Shipping',
    social: 'Social',
    confirmation: {
      title: 'Contact Us Confirmed | Stiletto Tools',
      subTitleLine1: 'Thank You for Contacting STILETTO® Tools',
      subTitleLine2: 'Your email has been received',
      body: 'We will reply to your inquiry within 1 business day. If You have any immediate needs please contact us by phone. Your email is included for your records.',
      questions: 'Contact us with any questions: ',
      ticketNumberText:
        'This is your ticket number. Please reference it for all communications about this support ticket.',
      yourEmailText: 'Your email to Stiletto',
    },
  },
  warrantyClaim: {
    title: 'Warranty Claim | Stiletto Tools',
    warranty: 'Warranty',
    submitAClaim: 'Submit a Warranty Claim Form',
    toolInformationTitle: 'Tool Information',
    productModelNumberToolName: 'Product Model Number / Tool Name',
    proofOfPurchase: 'Proof of Purchase',
    toolImageUpToFive:
      'Tool Image (Up to 5): Please include images of the FULL FRONT and FULL BACK of the tool',
    reasonForReturn: 'Reason for Return',
    magnetSize: 'Select Size of Magnet Hole on Your Hammer',
    placeOfPurchase: 'Place of Purchase',
    dateOfPurchase: 'Date of Purchase',
    comments: 'Comments',
    fileUploadSuccess: 'Uploaded {fileName} successfully',
    confirmation: {
      title: 'Warranty Confirmed| Stiletto Tools',
      subTitleLine1: 'Warranty Claim Submitted',
      subTitleLine2: 'Your warranty claim has been submitted',
      instructions:
        'We will review your claim within 3 business days and will follow up with the next steps in the process.',
      contactUsWithAnyQuestions: 'Contact us with any questions',
      ticketNumberReferenceText:
        'This is your ticket number. Please reference it for all communications about this support ticket.',
      warrantyClaimDetails: 'Warranty Claim Details',
      productInformation: 'Product Information',
      toolImages: 'Tool Images',
    },
    layout: {
      claim: 'Claim',
      policy: 'Policy',
    },
  },
  home: {
    title: 'Stiletto Tools',
    testimonials: 'Testimonials',
  },
  notFound: {
    title: 'Not Found| Stiletto Tools',
    message: "We can't seem to find the page you are looking for.",
    searchPlaceholder: 'Search product name or #',
  },
  policies: {
    policiesTitle: 'Policies',
    legalTab: 'Legal',
    privacyPolicyTab: 'Privacy Policy',
    legalPolicy: {
      title: 'Legal | Stiletto Tools',
      subTitle: 'Legal',
    },
    privacyPolicy: {
      title: 'Privacy Policy | Stiletto Tools',
      subTitle: 'Privacy',
    },
    warrantyPolicy: {
      title: 'Warranty Policy | Stiletto Tools',
      subTitle: 'Warranty Policy',
    },
  },
  searchFilters: {
    category: 'Category',
    handleType: 'Handle Type',
    handleMaterial: 'Handle Material',
    headWeight: 'Head Weight',
    replacements: 'Replacements',
  },
  faq: {
    helmetTitle: 'FAQ | Stiletto Tools',
    title: 'FAQ',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
  },
  cookieNotice: {
    title: 'Cookie Preferences',
  },
  justInTimeNotice: {
    disclaimer1: 'I understand that by clicking ',
    subscribeButton: 'Subscribe',
    sendButton: 'Send',
    disclaimer2: ' and submitting my contact information, I am providing that personal information to Stiletto and that Stiletto may use that personal information for the purposes for which it was provided, including to maintain my account information with Stiletto, to communicate with me, and to develop market intelligence. I may receive company communications from Stiletto related to Stiletto products. ',
    information: 'For more information about how we collect, use, and disclose personal information, review our ',
    privacyNoticeLink: 'Privacy Notice.',
    dataRights: ' To exercise your data protection rights or to opt-out of Stiletto communications, visit the ',
    yourPrivacyChoicesLink: 'Your Privacy Choices page.'
  }
}
