import {
  CustomerServiceOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router-dom';

import { Container, HighlightButton } from '../../../../components';
import { contactUsActions, countriesActions } from '../../../../store';
import { ContactSection } from './components/ContactSection';
import './styles.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { fbTrack, googleAnalyticsHelper } from '../../../../helpers';
import { JustInTimeNotice } from '../../../../components/JustInTimeNotice';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;
const { Content } = Layout;

const justInTimeColorScheme = 'dark';
const justiInTimeButtonType = 'sendButton';

const validateForm = (formVal) => {
  let honeypotFields = [
    'firstName',
    'lastName',
    'phoneNumber',
    'emailAddress',
    'message',
    'country',
  ];

  honeypotFields.forEach((field) => {
    if (typeof formVal[field] !== 'undefined') {
      return false;
    }
  });

  return true;
};

export const SubmitContactUs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // App State
  const countryList = useSelector((state) => state.countries.countryList);

  // Local state
  const [, setSaving] = useState(false);

  useEffect(() => {
    if (!countryList) {
      // hit api and load distributor
      dispatch(countriesActions.getCountries());
    }
  }, [dispatch, countryList]);

  const countryOptions =
    countryList &&
    countryList.map((item, index) => {
      return (
        <Option key={index} value={item.iso}>
          {item.name}
        </Option>
      );
    });

  const onClickHandler = (url) => {
    googleAnalyticsHelper('external link', 'click', url);

    window.open(url, '_blank', 'noopener, noreferrer');
  };

  /***
   * onSubmit will save the updated product info
   * @param values: the values of the form
   */
  const onSubmit = (values) => {
    if (validateForm(values)) {
      //Submit
      setSaving(true);

      googleAnalyticsHelper('form submit', 'button click', 'contact us');

      let obj = {
        FirstName: values.firstNameMke,
        LastName: values.lastNameMke,
        PhoneNumber: values.phoneNumberMke,
        EmailAddress: values.emailAddressMke,
        Country: values.countryMke,
        Message: values.messageMke,
      };
      fbTrack('track', 'CONTACT');
      dispatch(contactUsActions.insertContactUs(obj))
        .then((res) => {
          setSaving(false);
          history.push(`/contact/${res?.data?.data?.ticketNumber}`);
        })
        .catch(() => {
          setSaving(false);
          message.error(
            intl.formatMessage({ id: 'general.genericErrorMessage' })
          );
        });
    } else {
      // Do something
    }
  };

  return (
    <Container style={{ padding: 0 }}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'contactUs.title' })}</title>
      </Helmet>
      <Content>
        <Typography>
          <Fade delay={150}>
            <Row gutter={[48, 24]}>
              <Col xs={24} lg={12}>
                <Form
                  name='dynamic_form_nest_item'
                  onFinish={onSubmit}
                  hideRequiredMark={false}
                  layout='vertical'
                >
                  <Form.Item
                    name='firstNameMke'
                    label={<FormattedMessage id='general.firstName' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='given-name' />
                  </Form.Item>
                  <Form.Item
                    name='lastNameMke'
                    label={<FormattedMessage id='general.lastName' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Input name='family-name' />
                  </Form.Item>
                  <Form.Item
                    name='emailAddressMke'
                    label={<FormattedMessage id='general.emailAddress' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                      {
                        type: 'email',
                        message: (
                          <FormattedMessage id='validation.invalidEmail' />
                        ),
                      },
                    ]}
                  >
                    <Input name='email' inputMode='email' />
                  </Form.Item>
                  <Form.Item
                    name='phoneNumberMke'
                    label={<FormattedMessage id='general.phoneNumber' />}
                    initialValue=''
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id='validation.required' />,
                      },
                      {
                        pattern:
                          '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
                        message: (
                          <FormattedMessage id='validation.invalidPhoneNumber' />
                        ),
                      },
                    ]}
                  >
                    <Input inputMode='tel' name='tel' />
                  </Form.Item>
                  <Form.Item
                    name='countryMke'
                    label={<FormattedMessage id='general.country' />}
                    initialValue='US'
                    rules={[
                      {
                        required: false,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <Select
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp='children'
                      showSearch
                    >
                      <Option value=''>
                        {<FormattedMessage id='general.pleaseSelect' />}
                      </Option>
                      {countryOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='messageMke'
                    label={<FormattedMessage id='general.message' />}
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='validation.required' />,
                      },
                    ]}
                  >
                    <TextArea rows={4} autoComplete='off' />
                  </Form.Item>
                  <Form.Item
                    name='firstName'
                    label={<FormattedMessage id='general.firstName' />}
                    className='ohnohoney'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='lastName'
                    label={<FormattedMessage id='general.lastName' />}
                    className='ohnohoney'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='email'
                    label={<FormattedMessage id='general.emailAddress' />}
                    className='ohnohoney'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='phoneNumber'
                    label={<FormattedMessage id='general.phoneNumber' />}
                    className='ohnohoney'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='country'
                    label={<FormattedMessage id='general.countryRegion' />}
                    className='ohnohoney'
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name='message'
                    label={<FormattedMessage id='general.message' />}
                    className='ohnohoney'
                  >
                    <TextArea rows={4} />
                  </Form.Item>

                  <HighlightButton type='primary' htmlType='submit'>
                    <FormattedMessage id='general.send' />
                  </HighlightButton>
                </Form>
                <JustInTimeNotice
                  textColor={justInTimeColorScheme}
                  buttonType={justiInTimeButtonType}
                />
              </Col>
              <Col xs={24} lg={12} xl={9}>
                <ContactSection
                  title={<FormattedMessage id='general.phone' />}
                  icon={CustomerServiceOutlined}
                  itemDirection='vertical'
                >
                  <Text>
                    <a href='tel:1 (800) 987-1849'>1 (800) 987-1849</a>{' '}
                    (USA/CAN)
                  </Text>
                </ContactSection>
                <ContactSection
                  title={<FormattedMessage id='contactUs.mailShipping' />}
                  icon={EnvironmentOutlined}
                  itemDirection='vertical'
                >
                  <Text>13135 West Lisbon Road</Text>
                  <Text>Brookfield, WI 53005-2550</Text>
                </ContactSection>
                <ContactSection
                  title={intl.formatMessage({ id: 'contactUs.social' })}
                  itemDirection='horizontal'
                >
                  <a
                    title={intl.formatMessage({ id: 'socialMedia.facebook' })}
                    style={{ color: '#383838' }}
                    onClick={() =>
                      onClickHandler('https://www.facebook.com/StilettoTools')
                    }
                  >
                    <FacebookOutlined className='SocialMediaIcons' />
                  </a>
                  <a
                    title={intl.formatMessage({ id: 'socialMedia.twitter' })}
                    style={{ color: '#383838' }}
                    onClick={() =>
                      onClickHandler('https://twitter.com/Stiletto_Tools')
                    }
                  >
                    <TwitterOutlined className='SocialMediaIcons' />
                  </a>
                  <a
                    title={intl.formatMessage({ id: 'socialMedia.instagram' })}
                    style={{ color: '#383838' }}
                    onClick={() =>
                      onClickHandler('https://www.instagram.com/stilettotools/')
                    }
                  >
                    <InstagramOutlined className='SocialMediaIcons' />
                  </a>
                </ContactSection>
              </Col>
            </Row>
          </Fade>
        </Typography>
      </Content>
    </Container>
  );
};
