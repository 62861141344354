export function fbTrack(a, b) {
  if (
    typeof window !== 'undefined' &&
    window.OnetrustActiveGroups &&
    window.fbq
  ) {
    let activeGroups = window.OnetrustActiveGroups.split(',').filter(Boolean);
    // C0004 OR 4 means the user has accepted Targeting cookies
    if (
      window.fbq != null &&
      (activeGroups.includes('C0004') || activeGroups.includes('4'))
    ) {
      window.fbq(a, b);
    }
  }
}
