import createAxiosAction from '../createAxiosAction';
import { INSERT_CONTACT_US } from '../types';

export default {
  insertContactUs: (val) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/contactus`,
      body: { ContactUs: val },
      startAction: INSERT_CONTACT_US,
    });
  },
};
