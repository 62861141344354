import { Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { StringParam, useQueryParams } from 'use-query-params';
import { useIntl } from 'react-intl';

import { Container, Hero } from '../../../components';
import { pageContentsActions } from '../../../store';
import { HeroContainer } from '../../../components/Hero/styled';
import { FeaturedItem } from './components/FeaturedItem';

export const CategoryLandingPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dataKey = props.dataKey;

  // App State
  const content = useSelector((state) =>
    state.pageContents.content ? state.pageContents.content[dataKey] : null
  );
  const [queryParams] = useQueryParams({
    version: StringParam,
  });

  // local state
  const [loading, setLoading] = useState(!content);
  const helmetTitle = `${intl.formatMessage({
    id: props.titleKey,
  })} | ${intl.formatMessage({ id: 'home.title' })}`;

  /***
   * Load the page content if we haven't gotten it yet
   */
  useEffect(() => {
    if (!content)
      dispatch(
        pageContentsActions.getPageContent({
          ...queryParams,
          dataKey: dataKey,
        })
      ).finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, dispatch]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      {loading && (
        <Spin
          spinning={loading}
          size='large'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      {!loading && content && (
        <React.Fragment>
          <Container className='category'>
            <Hero
              title={content?.hero1Title}
              subText='' // Unused
              body={content?.hero1Body}
              image={content?.hero1Image}
              hyperlinkText={content?.hero1HyperlinkText}
              hyperlinkUrl={content?.hero1HyperlinkUrl}
              priceSpiderSku={content?.hero1PriceSpiderSku}
            />
          </Container>
          <Container className='category'>
            <Fade>
              <Row gutter={[16, 16]} flex-wrap='no-wrap'>
                {content?.featuredItems.map((item, index) => (
                  <FeaturedItem
                    key={index}
                    title={item.featuredTitle}
                    subText={item.featuredSubText}
                    image={item.featuredImage}
                    hyperlinkText={item.featuredHyperlinkText}
                    hyperlinkUrl={item.featuredHyperlinkUrl}
                    buttonText={item.featuredButtonText}
                    buttonUrl={item.featuredButtonUrl}
                    featureCount={content.featuredItems.length}
                  />
                ))}
              </Row>
            </Fade>
          </Container>
          <Container className='category'>
            <HeroContainer
              align='middle'
              topfade='true'
              image={content?.hero2Image}
            ></HeroContainer>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
