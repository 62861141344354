import { Layout, Typography, Row, Col, Input } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Container } from '../../components/Container';
import hammer from '../../assets/images/TB15SC.png';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

export const NotFound = () => {
  const intl = useIntl();
  const history = useHistory();

  const handleSearch = (value) => {
    history.push(`/search?q=${value}`);
  };

  return (
    <Container>
      <Helmet>
        <title>{intl.formatMessage({ id: 'notFound.title' })}</title>
      </Helmet>
      <Content style={{ textAlign: 'center' }}>
        <Row style={{ placeContent: 'center' }}>
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 3 }}
            style={{ margin: '5px' }}
          >
            <img src={hammer} alt={'Hammer'} style={{ height: '300px' }} />
          </Col>
          <Col
            xs={{ order: 1, span: 24 }}
            md={{ order: 2, span: 10 }}
            style={{ margin: '5px' }}
          >
            <Title level={1} style={{ fontWeight: 'bold' }}>
              {intl.formatMessage({ id: 'notFound.message' })}
            </Title>
            <Search
              style={{ border: '1px solid #b2b2b2' }}
              placeholder={intl.formatMessage({
                id: 'notFound.searchPlaceholder',
              })}
              onSearch={handleSearch}
            />
          </Col>
        </Row>
      </Content>
    </Container>
  );
};
