import createAxiosAction from '../createAxiosAction';
import { GET_EMAIL_SETTINGS } from '../types';

export default {
  getWarrantyClaimEmailSettings: () => {
    return createAxiosAction({
      url: `/api/v1/emailSettings/emailCustomerWarrantyClaim`,
      startAction: GET_EMAIL_SETTINGS,
    });
  },
};
