import { GET_COUNTRIES } from '../types';
import { success } from '../utils';

export const initialState = {
  countryList: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_COUNTRIES):
      return {
        ...state,
        countryList: action.payload.data,
      };
    default:
      return state;
  }
};
