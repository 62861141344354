import { Form, Input, Select } from 'antd';
import React from 'react';

const { TextArea } = Input;
const { Option } = Select;

// Helps detect bots from automatically filling out forms
export const HoneyPotFormItems = () => {
  return (
    <React.Fragment>
      <Form.Item name='firstName' label='First Name' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='lastName' label='Last Name' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='company' label='Company' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='phone' label='Phone' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='email' label='Email' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='address1' label='Address 1' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='address2' label='Address 2' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='city' label='City' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='state' label='State' className='ohnohoney'>
        <Select>
          <Option value=''>Please Select</Option>
        </Select>
      </Form.Item>
      <Form.Item name='postal' label='Zip/Postal Code' className='ohnohoney'>
        <Input />
      </Form.Item>
      <Form.Item name='country' label='Country' className='ohnohoney'>
        <Select>
          <Option value=''>Please Select</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name='modelNumberToolName'
        label='Product Model Number / Tool Name'
        className='ohnohoney'
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='reasonForReturn'
        label='Reason for Return'
        className='ohnohoney'
      >
        <TextArea rows={4} />
      </Form.Item>
    </React.Fragment>
  );
};
