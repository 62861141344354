import { Breadcrumb, Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

import milwaukee from '../../assets/images/milwaukee.png';
import tools from '../../assets/images/tools.png';
import { Container } from '../../components/Container';
import { ImageZoom } from '../../components/ImageZoom';
import { ProductSearchResult } from '../../components/ProductSearchResult';

const { Title, Paragraph } = Typography;

export const BrowseProducts = () => {
  return (
    <Container style={{ padding: 24 }}>
      <Helmet>
        <title>All Products | Stiletto Tools</title>
      </Helmet>
      <Breadcrumb style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={'/'}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Browse</Breadcrumb.Item>
      </Breadcrumb>
      <Fade>
        <Typography>
          <Title>Browse By Category</Title>
        </Typography>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} xxl={6}>
            <ImageZoom image={tools} title='Striking Tools' />
          </Col>
          <Col xs={24} md={12} xxl={6}>
            <ImageZoom image={milwaukee} title='Pry Bars' />
          </Col>
          <Col xs={24} md={12} xxl={6}>
            <ImageZoom image={tools} title='Accessories' />
          </Col>
          <Col xs={24} md={12} xxl={6}>
            <ImageZoom image={milwaukee} title='Replacement Parts' />
          </Col>
        </Row>
      </Fade>
      <Fade delay={400}>
        <Divider />
        <Typography>
          <Paragraph style={{ fontSize: '1.4em', textAlign: 'center' }}>
            Stiletto Tool Company has been synonymous with high quality hand
            tools dating back to the early days of the California Gold Rush.
            Since 1849 we have been providing professionals with the tools that
            have literally shaped the building of the North American West. With
            its long history and strong reputation, the brand has evolved into
            the modern day standard for innovative, lightweight, ergonomic,
            productive, and professional tools.
          </Paragraph>
        </Typography>
        <Divider />
      </Fade>
      <Fade delay={800}>
        <Typography>
          <Title>Featured Products</Title>
        </Typography>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <ProductSearchResult
              product={{
                title:
                  '12oz Titanium Milled Face / 16" "Hybrid" Fiberglass Handle',
                skuAlias: 'TI12MC-F',
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <ProductSearchResult
              product={{
                title:
                  '12oz Titanium Milled Face / 16" "Hybrid" Fiberglass Handle',
                skuAlias: 'TI12MC-F',
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <ProductSearchResult
              product={{
                title:
                  '12oz Titanium Milled Face / 16" "Hybrid" Fiberglass Handle',
                skuAlias: 'TI12MC-F',
              }}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <ProductSearchResult
              product={{
                title:
                  '12oz Titanium Milled Face / 16" "Hybrid" Fiberglass Handle',
                skuAlias: 'TI12MC-F',
              }}
            />
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};
