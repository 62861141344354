import { Menu, Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const StyledMenu = styled(Menu)`
  height: 100%;

  .ant-menu-item {
    background-color: initial !important;

    &:hover {
      color: #1890ff;
    }
  }

  .ant-menu-item-selected {
    background-color: initial !important;
    color: #1890ff;

    &:after {
      transform: scaleY(0) !important;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
`;

export const StyledSider = styled(Sider)`
  background: white;
  margin-right: ${(props) => (props.collapsed ? '0px' : '16px')};
`;
