import styled from 'styled-components';
import { Row } from 'antd';

export const HeroBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.7);
  margin: 0 24px;
  height: 100%;
  padding: 50px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Neue Helvetica W05 Extended', Arial, Helvetica, sans-serif;
  }

  h1 {
    font-size: 42px;
    line-height: 72px;
    font-weight: 900;
    margin-bottom: 0;
  }

  h2 {
    font-size: 25px;
    line-height: 26px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  .markdown-light ul {
    color: white;
  }

  .markdown-light {
    margin-bottom: 24px;

    a {
      color: light-blue;
    }
  }

  a {
    font-weight: bold;
  }

  // really small devices like iphone se
  @media (max-width: 360px) {
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 20px;
    }
  }

  // average sizes mobile devices
  @media (max-width: 480px) {
    margin: 0;
    padding: 24px;
    height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;

    .hero-subText {
      display: none;
    }

    .hero-body {
      display: none;
    }

    h1 {
      font-size: 34px;
    }
    h2 {
      font-size: 22px;
    }
  }
`;

export const HeroContainer = styled(Row)`
  height: 500px;

  background-image: ${(props) =>
    props.topfade
      ? `linear-gradient(to top,rgba(255,255,255,0)0 70%,rgba(255,255,255,1)  100%), url(${props.image})`
      : `url(${props.image})`};

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
