import createAxiosAction from '../createAxiosAction';
import { GET_COUNTRIES } from '../types';

export default {
  getCountries: () => {
    return createAxiosAction({
      url: `/api/v1/countries`,
      startAction: GET_COUNTRIES,
    });
  },
};
