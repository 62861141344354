import { Checkbox } from 'antd';
import React from 'react';

export const FilterItem = ({ queryParam, value, updateFunction, label }) => {
  return (
    <Checkbox
      checked={queryParam.includes(value)}
      onChange={(e) => updateFunction(e.target.checked, value)}
    >
      {label}
    </Checkbox>
  );
};
