import { Col, Space } from 'antd';
import { Fade } from 'react-reveal';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { HighlightButton } from '../HighlightButton';
import { HeroContainer, HeroBackdrop } from './styled';

export const Hero = ({
  title,
  subText,
  body,
  image,
  hyperlinkText,
  hyperlinkUrl,
  buttonText,
  buttonUrl,
  priceSpiderSku
}) => {
  const history = useHistory();

  return (
    <HeroContainer align='middle' image={image}>
      <Col xs={24} sm={24} md={14} lg={12} xl={9} style={{ height: '100%' }}>
        <HeroBackdrop>
          <Fade top delay={200}>
            {title && (
              <ReactMarkdown className={'markdown-light hero-title'}>
                {title}
              </ReactMarkdown>
            )}
          </Fade>
          <Fade delay={350}>
            {subText && (
              <ReactMarkdown className={'markdown-light hero-subText'}>
                {subText}
              </ReactMarkdown>
            )}
            {body && (
              <ReactMarkdown className={'markdown-light hero-body'}>
                {body}
              </ReactMarkdown>
            )}
            <React.Fragment>
              <Space direction={'vertical'}>
                {hyperlinkText && hyperlinkUrl && (
                  <Link to={hyperlinkUrl}>
                    {hyperlinkText} <RightOutlined />
                  </Link>
                )}
                {buttonText && buttonUrl && (
                  <div style={{ margin: '24px 0' }}>
                    <HighlightButton
                      onClick={() => {
                        history.push(`/${buttonUrl}`);
                      }}
                    >
                      {buttonText}
                    </HighlightButton>
                  </div>
                )}
                {priceSpiderSku && (
                  <div style={{ margin: '24px 0' }}>
                    <div
                      className='ps-widget'
                      ps-sku={priceSpiderSku}
                      id='where-to-buy'
                    ></div>
                    {window.PriceSpider.rebind()}
                  </div>
                )}
              </Space>
            </React.Fragment>
          </Fade>
        </HeroBackdrop>
      </Col>
    </HeroContainer>
  );
};
