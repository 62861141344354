import { RightOutlined } from '@ant-design/icons';
import {
  Carousel,
  Col,
  Divider,
  Layout,
  message,
  PageHeader,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'react-reveal';
import { useHistory, useParams } from 'react-router';

import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';
import {
  PageLoader,
  ProductImage,
  TabLink,
  Link,
  Container,
} from '../../components';

import { productHelper, googleAnalyticsHelper } from '../../helpers';
import { productsActions } from '../../store';
import { useFeatureFlags } from '../../hooks';
import { ProductDimensionMapping } from './ProductDimensionMapping';
import { ProductSpecificationMapping } from './ProductSpecificationMapping';
import {
  CarouselImageContainer,
  ProductInformation,
  ProductTitle,
  ProductSku,
  SpecificationsContainer,
  SpecificationsTable,
} from './styled';
import './styles.less';

const { Content } = Layout;

export const ProductDetails = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { sku } = useParams();
  const history = useHistory();
  const { squaresProductLaunch } = useFeatureFlags();

  // app state
  const productDetails = useSelector((state) => state.products.productDetails);

  // local state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(productsActions.getProduct(sku))
      .then(() => {})
      .catch((error) => {
        if (error.response.status === 404) {
          message.warning(
            intl.formatMessage({
              id: 'productDetails.validation.productNotFound',
            })
          );
          history.replace('/notfound');
        } else {
          message.error(
            intl.formatMessage({ id: 'general.genericErrorMessage' })
          );
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sku]);

  const onChangeHandler = (title) => {
    googleAnalyticsHelper('media gallery', 'view photo', title);
  };

  // Map image list
  let productImageList = productDetails?.imageUrls;
  let imageSlides = productImageList?.map((imageUrl, index) => {
    return <ProductImage key={index} imageUrl={imageUrl} maxHeight={70} />;
  });

  let selectedImageSlides = productImageList?.map((imageUrl, index) => {
    return (
      <div className='product-details-slide' key={`image-${index}`}>
        <img
          style={{ maxHeight: '500px', maxWidth: '100%' }}
          src={imageUrl}
          alt=''
        />
      </div>
    );
  });

  // Image Carousel settings
  const productImageCarouselSettings = {
    customPaging: function (i) {
      return <CarouselImageContainer>{imageSlides[i]}</CarouselImageContainer>;
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  // Map features list
  const featureList = productDetails?.features?.map((feature, index) => {
    return (
      <li key={`feature-${index}`}>
        <ReactMarkdown source={feature} />
      </li>
    );
  });

  // Map related products
  const relatedProducts = productDetails?.relatedProducts.map(
    (product, index) => {
      return (
        <li key={`related-${index}`} style={{ marginBottom: '1rem' }}>
          <Link
            to={`/p/${productHelper.titleToSlug(product.title)}/${
              product.skuAlias
            }`}
          >
            {product.title} <RightOutlined />
          </Link>
        </li>
      );
    }
  );

  const metaTitle = productDetails
    ? `${productDetails?.title} | Stiletto Tools`
    : 'Stiletto Tools';

  return (
    <Layout>
      {productDetails && !isLoading && (
        <Helmet>
          <title>{metaTitle}</title>
          <meta name='description' content={productDetails?.overview} />
          <meta
            name='keywords'
            content={['Stiletto'].concat(productDetails?.categories)}
          />

          <meta property='og:title' content={metaTitle} />
          <meta property='og:description' content={productDetails?.overview} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          {productDetails?.imageUrls?.length > 0 && (
            <meta property='og:image' content={productDetails.imageUrls[0]} />
          )}

          <meta name='twitter:card' content='summary' />
          <meta property='twitter:title' content={metaTitle} />
          <meta
            property='twitter:description'
            content={productDetails?.overview}
          />
          <meta property='twitter:url' content={window.location.href} />
          {productDetails?.imageUrls?.length > 0 && (
            <meta
              property='twitter:image'
              content={productDetails.imageUrls[0]}
            />
          )}
        </Helmet>
      )}
      <Content>
        <PageLoader isLoading={isLoading} />
        {productDetails && !isLoading && (
          <>
            <Container>
              <PageHeader
                style={{ padding: 0 }}
                title={
                  <h1>{intl.formatMessage({ id: 'productDetails.title' })}</h1>
                }
                extra={[
                  <TabLink key='striking-tools' to='/search?cat=Striking Tools'>
                    <FormattedMessage id='productDetails.strikingTools' />
                  </TabLink>,
                  squaresProductLaunch ? (
                    <TabLink key='squares' to='/search?cat=Squares'>
                      <FormattedMessage id='productDetails.squares' />
                    </TabLink>
                  ) : null,
                  <TabLink key='pre-bars' to='/search?cat=Pry Bars'>
                    <FormattedMessage id='productDetails.pryBars' />
                  </TabLink>,
                  <TabLink key='Accessories' to='/search?cat=Accessories'>
                    <FormattedMessage id='productDetails.accessories' />
                  </TabLink>,
                  <TabLink
                    key='replacements'
                    to='/search?cat=Replacement Parts'
                  >
                    <FormattedMessage id='productDetails.replacements' />
                  </TabLink>,
                ]}
              />
              <Divider />
              <Row style={{ marginTop: '1rem' }}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  style={{ textAlign: 'center' }}
                >
                  <Fade>
                    <div className={'productDetailsCarousel'}>
                      {selectedImageSlides?.length > 0 ? (
                        <Carousel
                          {...productImageCarouselSettings}
                          afterChange={() =>
                            onChangeHandler(productDetails.title)
                          }
                        >
                          {selectedImageSlides}
                        </Carousel>
                      ) : (
                        <Logo width={300} style={{ color: 'black' }} />
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Typography>
                    <Fade top>
                      <ProductSku>{productDetails?.skuAlias}</ProductSku>
                      <ProductTitle>{productDetails?.title}</ProductTitle>
                    </Fade>
                    <Fade delay={500}>
                      <Row style={{ marginBottom: '1rem' }}>
                        <div
                          className='ps-widget'
                          ps-sku={productDetails?.skuAlias}
                          id='where-to-buy'
                        ></div>
                        {window.PriceSpider.rebind()}
                      </Row>
                      <ProductInformation>
                        <ReactMarkdown source={productDetails?.overview} />
                      </ProductInformation>
                    </Fade>
                    <Fade delay={500}>
                      <ProductInformation>
                        <Fade cascade delay={700}>
                          <ul>{featureList}</ul>
                        </Fade>
                      </ProductInformation>
                    </Fade>
                    {relatedProducts && relatedProducts.length > 0 && (
                      <Fade delay={700}>
                        <ProductInformation>
                          <Fade cascade delay={700}>
                            <strong>
                              <FormattedMessage id='productDetails.relatedProductsLabel' />
                            </strong>
                            <ul style={{ marginTop: '1rem' }}>
                              {relatedProducts}
                            </ul>
                          </Fade>
                        </ProductInformation>
                      </Fade>
                    )}
                  </Typography>
                </Col>
              </Row>
            </Container>
            <SpecificationsContainer>
              <Container style={{ maxWidth: 1200 }}>
                {productDetails &&
                  (Object.keys(productDetails.dimensions).length > 0 ||
                    Object.keys(productDetails.metadata).length > 0) && (
                    <React.Fragment>
                      <Fade delay={500}>
                        <h3 style={{ color: 'white' }}>
                          <FormattedMessage id='productDetails.specificationsLabel' />
                        </h3>
                      </Fade>

                      <Fade delay={700}>
                        <Row gutter={[16, 16]}>
                          {Object.keys(productDetails.dimensions).length >
                            0 && (
                            <Col xs={24} sm={12} md={7}>
                              <div>
                                <strong>
                                  <FormattedMessage id='productDetails.dimensions.dimensionsLabel' />
                                </strong>
                              </div>
                              <SpecificationsTable>
                                <tbody>
                                  <ProductDimensionMapping />
                                </tbody>
                              </SpecificationsTable>
                            </Col>
                          )}

                          {Object.keys(productDetails.metadata).length > 0 && (
                            <Col xs={24} sm={12} md={7}>
                              <div>
                                <strong>
                                  <FormattedMessage id='productDetails.metadata.specificationDetailsLabel' />
                                </strong>
                              </div>
                              <SpecificationsTable>
                                <tbody>
                                  <ProductSpecificationMapping />
                                </tbody>
                              </SpecificationsTable>
                            </Col>
                          )}
                          <Col xs={24} md={10}>
                            <div>
                              <strong>
                                <FormattedMessage id='productDetails.warrantyCertifications' />
                              </strong>
                            </div>
                            <SpecificationsTable>
                              <tbody>
                                <tr>
                                  <td>
                                    <Link
                                      to={'/warranty-policy'}
                                      style={{ color: 'white' }}
                                    >
                                      <FormattedMessage id='productDetails.warrantyPolicy' />{' '}
                                      <RightOutlined />
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </SpecificationsTable>
                          </Col>
                        </Row>
                      </Fade>
                    </React.Fragment>
                  )}
              </Container>
            </SpecificationsContainer>
          </>
        )}
      </Content>
    </Layout>
  );
};
