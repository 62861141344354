// Locales
export const UPDATE_LOCALE = 'UPDATE_LOCALE';

// Products
export const GET_PRODUCT = 'GET_PRODUCT';
export const SEARCHING_PRODUCTS = 'SEARCHING_PRODUCTS';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const SET_SEARCH_FILTERS_SIDER = 'SET_SEARCH_FILTERS_SIDER';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT_IMAGE = 'REMOVE_PRODUCT_IMAGE';
export const SET_IMAGE_ORDER = 'SET_IMAGE_ORDER';

// Distributors
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';
export const SET_DISTRIBUTOR = 'SET_DISTRIBUTOR;';
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR';
export const UPLOAD_DISTRIBUTOR_IMAGE = 'UPLOAD_DISTRIBUTOR_IMAGE';

// Page Contents
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT';

// States
export const GET_STATES = 'GET_STATES';

// Countries
export const GET_COUNTRIES = 'GET_COUNTRIES';

// Warranties
export const INSERT_WARRANTY_CLAIM = 'INSERT_WARRANTY_CLAIM';
export const UPLOAD_WARRANTY_CLAIM_FILE = 'UPLOAD_WARRANTY_CLAIM_FILE';
export const SUGGEST_PRODUCTS = 'SUGGEST_PRODUCTS';

// Contact Us
export const INSERT_CONTACT_US = 'INSERT_CONTACT_US';

// Newsletter Subscribers
export const INSERT_NEWSLETTER_SIGNUP = 'INSERT_NEWSLETTER_SIGNUP';

// Retail Stores
export const GET_INTERNATIONAL_LOCATIONS = 'GET_INTERNATIONAL_LOCATIONS';

// Reason Codes
export const GET_REASON_CODES = 'GET_REASON_CODES';

// Magnet Sizes
export const GET_MAGNET_SIZES = 'GET_MAGNET_SIZES';

// Email Settings
export const GET_EMAIL_SETTINGS = 'GET_EMAIL_SETTINGS';

// Configuration
export const GET_ACTIVE_FEATURES = 'GET_ACTIVE_FEATURES';
