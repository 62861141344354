import { Spin } from 'antd';
import React from 'react';

export const PageLoader = ({ isLoading }) => {
  return (
    <Spin
      size='large'
      wrapperClassName='stiletto-page-loader'
      spinning={isLoading}
    >
      <span></span>
    </Spin>
  );
};
