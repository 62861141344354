import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';

const getColor = (props) => {
  return props.theme === 'dark' ? 'white' : '#305EA4';
};

export const Link = styled(ReactLink)`
  color: ${(props) => getColor(props)};

  &:hover {
    color: ${(props) => getColor(props)};
  }
`;
