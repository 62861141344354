import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 1rem;

  &.home,
  &.category {
    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.category {
    padding-top: 0;
  }
`;
