import { GET_EMAIL_SETTINGS } from '../types';
import { error, success } from '../utils';

export const initialState = {
  emailSettings: {
    isLoading: false,
    isError: false,
    settings: null,
  },
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_EMAIL_SETTINGS:
      return {
        ...state,
        emailSettings: {
          isLoading: true,
          isError: false,
          settings: null,
        },
      };
    case success(GET_EMAIL_SETTINGS):
      return {
        ...state,
        emailSettings: {
          isLoading: false,
          isError: false,
          settings: action.payload.data,
        },
      };
    case error(GET_EMAIL_SETTINGS):
      return {
        ...state,
        emailSettings: {
          isLoading: false,
          isError: true,
          settings: null,
        },
      };
    default:
      return state;
  }
};
