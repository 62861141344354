import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import styled from 'styled-components';

export const SearchForm = styled(Form)`
  display: flex;
  align-items: center;

  @media (max-width: 1350px) {
    display: none;
  }
`;

export const SearchFormItem = styled(Form.Item)`
  margin: auto;
  display: flex;
  align-items: center;

  label {
    color: white;

    ::after {
      content: '';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
`;

export const SearchBox = styled(Input)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 15px;
  cursor: text;
  width: 300px;
  background: rgb(235, 235, 235);
  color: black;
  & + label .search-icon {
    color: black;
  }

  ::placeholder {
    color: #2b2b2b;
    opacity: 0.7;
  }
`;

export const DynamicSearchBox = styled(Input)`
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  color: white;
  border: none;

  & + label .search-icon {
    color: white;
  }

  &:hover {
    color: white;
    background: rgb(200, 200, 200);
    box-shadow: 0 0 0 5px rgb(61, 71, 82);
    & + label .search-icon {
      color: black;
    }
  }

  &:focus {
    transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s,
      background 0.6s;
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 15px;
    cursor: text;
    width: 300px;
    background: rgb(235, 235, 235);
    color: black;
    & + label .search-icon {
      color: black;
    }
  }

  &:not(:focus) {
    text-indent: -5000px;
  } // for more-graceful falling back (:not browsers likely support indent)
`;

export const SearchButton = styled(SearchOutlined)`
  position: relative;
  left: -30px;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
`;
