import createAxiosAction from '../createAxiosAction';
import { INSERT_NEWSLETTER_SIGNUP } from '../types';

export default {
  insertNewsletterSignup: (val) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/newslettersignup`,
      body: {
        FirstName: val.firstName,
        LastName: val.lastName,
        EmailAddress: val.emailMke,
        Trade: val.trade,
        Zip: val.zip,
      },
      startAction: INSERT_NEWSLETTER_SIGNUP,
    });
  },
};
