import createAxiosAction from '../createAxiosAction';
import { GET_STATES } from '../types';

export default {
  getStates: () => {
    return createAxiosAction({
      url: `/api/v1/states`,
      startAction: GET_STATES,
    });
  },
};
