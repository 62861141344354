import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { PageLoader, Woops } from '../../../components';
import { retailStoresActions } from '../../../store';
import { CompanyLink, Location, LocationContainer } from './styled';
import { googleAnalyticsHelper } from '../../../helpers';

export const InternationalLocations = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  // app state
  const internationalLocations = useSelector(
    (state) => state.retailStores.internationalLocations,
    shallowEqual
  );
  const isError = useSelector((state) => state.retailStores.isError);

  // local state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(retailStoresActions.getInternationalLocations()).finally(() =>
      setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = (location) => {
    googleAnalyticsHelper(
      'where to buy international',
      'click',
      location.company
    );

    window.open(
      `${location?.url?.includes('http') ? '' : '//'}${location.url}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'internationalLocations.title' })}
        </title>
      </Helmet>
      <LocationContainer>
        {!isError && (
          <React.Fragment>
            <h3>European Locations</h3>
            <PageLoader isLoading={loading} />
            {internationalLocations &&
              internationalLocations.length > 0 &&
              !loading && (
                <React.Fragment>
                  {internationalLocations.map((location, index) => {
                    return (
                      <Location key={`location-${index}`}>
                        {location.url ? (
                          <CompanyLink onClick={() => onClickHandler(location)}>
                            {location.company}
                          </CompanyLink>
                        ) : (
                          <div>{location.company}</div>
                        )}
                        <div>
                          {location.phoneNumber} -- {location.addressLine1} --{' '}
                          {location.postalCode} {location.city}
                        </div>
                        <div>{location.country}</div>
                      </Location>
                    );
                  })}
                </React.Fragment>
              )}
          </React.Fragment>
        )}
        <Woops isVisible={isError} />
      </LocationContainer>
    </React.Fragment>
  );
};
