import styled from 'styled-components';

export const Parallax = styled.div`
  background-image: url(${(props) => props.image});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 560px;
`;
