import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';

const getColor = (props) => {
  // if we are on the active link
  if (window.location.pathname === props.to) {
    if (props.theme && props.theme === 'dark') {
      return 'white';
    } else {
      return 'black';
    }
  } else if (props.theme && props.theme === 'dark') {
    return 'white';
  } else {
    return '#305EA4';
  }
};

const StyledTabLink = styled(Link)`
  color: ${(props) => getColor(props)};
  font-weight: ${(props) =>
    window.location.pathname === props.to ? 'bold' : 'normal'};

  &:hover {
    color: ${(props) => getColor(props)};
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const TabLink = ({ children, ...others }) => {
  return (
    <StyledTabLink {...others}>
      {children}
      <RightOutlined />
    </StyledTabLink>
  );
};
