import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
`;

export const ClaimDetailsTable = styled.table`
  td {
    padding-right: 2rem; /* Apply cell padding */
  }
`;
