import { success } from '../utils';
import { INSERT_WARRANTY_CLAIM, SUGGEST_PRODUCTS } from '../types';

export const initialState = {
  warrantyClaim: null,
  productSuggestions: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(INSERT_WARRANTY_CLAIM):
      return {
        ...state,
        warrantyClaim: action.payload.data,
      };
    case success(SUGGEST_PRODUCTS):
      return {
        ...state,
        productSuggestions: action.payload.data,
      };
    default:
      return state;
  }
};
