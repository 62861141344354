import * as React from 'react';
import { Route, Switch } from 'react-router';
import ReactGA from 'react-ga';
import {
  AboutUs,
  BrowseProducts,
  ContactUsConfirmation,
  ContactUsLayout,
  Home,
  InternationalLocations,
  InternationalLocationsLayout,
  Legal,
  NotFound,
  PolicyLayout,
  PrivacyPolicy,
  ProductDetails,
  ProductSearch,
  SiteLayout,
  SubmitContactUs,
  SubmitWarrantyClaim,
  WarrantyConfirmation,
  WarrantyLayout,
  WarrantyPolicy,
} from './containers';
import { FAQ } from './containers/CustomerService/FAQ';
import { FAQLayout } from './containers/CustomerService/FAQ/FAQLayout';
import { ProductDetailsLayout } from './containers/ProductDetails/ProductDetailsLayout';
import { googleAnalyticsHelper } from './helpers';
import { CategoryLandingPage, CategoryLayout } from './containers/Categories';

ReactGA.initialize('UA-194260377-1');

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  googleAnalyticsHelper(
    'pageview',
    'pageview',
    rest.location.pathname + rest.location.search
  );

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...rest} {...props}>
          <Component
            {...props}
            dataKey={rest.dataKey}
            titleKey={rest.titleKey}
          />
        </Layout>
      )}
    />
  );
};

export default () => (
  <Switch>
    <AppRoute exact path='/' component={Home} layout={SiteLayout} />
    <AppRoute
      exact
      path='/search'
      component={ProductSearch}
      layout={SiteLayout}
    />
    <AppRoute
      exact
      path='/category/striking-tools'
      component={CategoryLandingPage}
      dataKey='strikingTools'
      titleKey='navigation.strikingTools'
      layout={CategoryLayout}
    />
    <AppRoute
      exact
      path='/category/squares'
      component={CategoryLandingPage}
      dataKey='squares'
      titleKey='navigation.squares'
      layout={CategoryLayout}
    />
    <AppRoute
      exact
      path='/category/pry-bars'
      component={CategoryLandingPage}
      dataKey='pryBars'
      titleKey='navigation.pryBars'
      layout={CategoryLayout}
    />
    <AppRoute
      exact
      path='/category/accessories'
      component={CategoryLandingPage}
      dataKey='accessories'
      titleKey='navigation.accessories'
      layout={CategoryLayout}
    />
    <AppRoute exact path='/about' component={AboutUs} layout={SiteLayout} />
    <AppRoute
      exact
      path='/contact'
      component={SubmitContactUs}
      layout={ContactUsLayout}
    />
    <AppRoute
      exact
      path='/contact/:id'
      component={ContactUsConfirmation}
      layout={ContactUsLayout}
    />
    <AppRoute
      exact
      path={'/warranty'}
      component={SubmitWarrantyClaim}
      layout={WarrantyLayout}
    />
    <AppRoute
      exact
      path='/warranty/:id'
      component={WarrantyConfirmation}
      layout={WarrantyLayout}
    />
    <AppRoute
      exact
      path='/p/:title/:sku'
      component={ProductDetails}
      layout={ProductDetailsLayout}
    />
    <AppRoute
      exact
      path='/b/all-products'
      component={BrowseProducts}
      layout={SiteLayout}
    />
    <AppRoute
      exact
      path='/international-locations'
      component={InternationalLocations}
      layout={InternationalLocationsLayout}
    />
    <AppRoute
      exact
      path='/privacy-policy'
      component={PrivacyPolicy}
      layout={PolicyLayout}
    />
    <AppRoute exact path='/legal' component={Legal} layout={PolicyLayout} />
    <AppRoute
      exact
      path='/warranty-policy'
      component={WarrantyPolicy}
      layout={WarrantyLayout}
    />
    <AppRoute exact path={'/faq'} component={FAQ} layout={FAQLayout} />
    <AppRoute path='/notfound' component={NotFound} layout={SiteLayout} />
    <AppRoute path='*' component={NotFound} layout={SiteLayout} />
  </Switch>
);
