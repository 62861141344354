import { GET_PAGE_CONTENT } from '../types';
import { success } from '../utils';

export const initialState = {
  content: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_PAGE_CONTENT):
      return {
        ...state,
        content: {
          ...state.content,
          [action.dataKey]: action.payload.data.content,
        },
      };
    default:
      return state;
  }
};
