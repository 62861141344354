import { Button } from 'antd';
import styled from 'styled-components';

export const HighlightButton = styled(Button)`
  background-color: #3469ad;
  border-color: white;
  color: white;

  &:hover {
    background-color: #23477e;
    border-color: white;
    color: white;
  }
`;
