import React from 'react';
import { Fade } from 'react-reveal';

export const Woops = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <Fade>
      <div style={{ padding: '5rem' }}>
        <h1>Hmmm...something broke.</h1>
        <h3>Refresh the page and try again.</h3>
      </div>
    </Fade>
  );
};
