import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import history from '../history';

let instrumentationKey;

if (
  window.location.host === 'dev.stiletto.com' ||
  window.location.host === 'stil-d-ncus-web-1-svc.azurewebsites.net'
) {
  instrumentationKey = 'de813080-5bcf-4db7-93ad-1dd8f1f1f941';
} else if (
  window.location.host === 'staging.stiletto.com' ||
  window.location.host === 'stil-s-ncus-web-1-svc.azurewebsites.net'
) {
  instrumentationKey = 'b0a8443f-3c92-4744-8807-2bfa09953ccc';
} else {
  instrumentationKey = '257f6fb7-06f4-4769-a413-b1fc566dc544';
}

const filterTelemetry = (envelope) => {
  if (
    envelope?.data?.message &&
    envelope?.data?.message?.includes('ResizeObserver loop limit exceeded')
  ) {
    return false;
  }

  // suppressing CORS exceptions: https://github.com/microsoft/ApplicationInsights-JS/issues/1383
  // note, we were only seeing this error on iOS devices only
  if (envelope?.baseType === 'ExceptionData') {
    if (
      typeof envelope?.baseData?.exceptions === 'undefined' &&
      envelope?.baseData?.message &&
      envelope?.baseData?.message?.includes(
        'same-origin policy prevents us from getting the details of this exception'
      ) >= 0
    ) {
      return false;
    }
  }

  return true;
};

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        debug: false,
        history: history,
      },
    },
  },
});

appInsights = appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(filterTelemetry);

const withAppInsights = (component) => withAITracking(reactPlugin, component);

export { appInsights, withAppInsights };
