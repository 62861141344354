import { GET_ACTIVE_FEATURES } from '../types';
import { error, success } from '../utils';

export let initialState = {
  getActiveFeatures: {
    isLoading: false,
    isError: false,
    activeFeatures: [],
  },
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_ACTIVE_FEATURES:
      return {
        ...state,
        getActiveFeatures: {
          isLoading: true,
          isError: false,
          activeFeatures: [],
        },
      };
    case success(GET_ACTIVE_FEATURES):
      return {
        ...state,
        getActiveFeatures: {
          isLoading: false,
          isError: false,
          activeFeatures: action.payload.data,
        },
      };
    case error(GET_ACTIVE_FEATURES):
      return {
        ...state,
        getActiveFeatures: {
          isLoading: false,
          isError: true,
          activeFeatures: [],
        },
      };
    default:
      return state;
  }
};
