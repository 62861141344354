import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as Locales from './Locales';
import * as PageContents from './PageContents';
import * as Products from './Products';
import * as Distributors from './Distributors';
import * as States from './States';
import * as Countries from './Countries';
import * as WarrantyClaims from './WarrantyClaims';
import * as ContactUs from './ContactUs';
import * as RetailStores from './RetailStores';
import * as ReasonCodes from './ReasonCodes';
import * as MagnetSizes from './MagnetSizes';
import * as EmailSettings from './EmailSettings';
import * as Configuration from './Configuration';

export default function configureStore(history, initialState) {
  const reducers = {
    locales: Locales.reducer,
    pageContents: PageContents.reducer,
    products: Products.reducer,
    distributors: Distributors.reducer,
    states: States.reducer,
    countries: Countries.reducer,
    warrantyClaim: WarrantyClaims.reducer,
    contactUs: ContactUs.reducer,
    retailStores: RetailStores.reducer,
    reasonCodes: ReasonCodes.reducer,
    magnetSizes: MagnetSizes.reducer,
    emailSettings: EmailSettings.reducer,
    configuration: Configuration.reducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (
    isDevelopment &&
    typeof window !== 'undefined' &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
