import { Space } from 'antd';
import React from 'react';

export const ContactSection = ({
  title,
  icon: Icon,
  children,
  itemDirection,
}) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <div>
        <strong>{title}</strong>
      </div>
      <div style={{ display: 'flex', paddingTop: '.5rem' }}>
        {Icon && (
          <div style={{ paddingRight: '1rem' }}>
            <Icon style={{ fontSize: 24 }} />
          </div>
        )}
        <Space direction={itemDirection}>{children}</Space>
      </div>
    </div>
  );
};
