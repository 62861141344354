import { Divider, PageHeader } from 'antd';
import React from 'react';

import { Container } from '../../../components';
import { SiteLayout } from '../../SiteLayout';
import { StoreLinks } from '../components/StoreLinks';
import { useIntl } from 'react-intl';
import { fbTrack } from '../../../helpers';

export const WhereToBuyLayout = ({ children, headerTheme, contentTheme }) => {
  const intl = useIntl();
  fbTrack('track', 'INITIATE_CHECKOUT');

  return (
    <SiteLayout theme={contentTheme}>
      <div style={{ background: headerTheme === 'dark' ? '#2b2b2b' : 'white' }}>
        <Container style={{ paddingBottom: 0 }}>
          <PageHeader
            style={{ padding: 0 }}
            title={
              <h1 style={{ color: headerTheme === 'dark' ? 'white' : 'black' }}>
                {intl.formatMessage({
                  id: 'navigation.whereToBuy',
                })}
              </h1>
            }
          />
          <Divider />
          <StoreLinks theme={headerTheme} />
        </Container>
        <div
          style={{ background: contentTheme === 'dark' ? '#2b2b2b' : 'white' }}
        >
          <Container style={{ paddingTop: 0 }}>{children}</Container>
        </div>
      </div>
    </SiteLayout>
  );
};

export const InternationalLocationsLayout = ({ children }) => {
  fbTrack('track', 'INITIATE_CHECKOUT');
  return <WhereToBuyLayout headerTheme='dark'>{children}</WhereToBuyLayout>;
};
