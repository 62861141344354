import React from 'react';
import { FileTextFilled } from '@ant-design/icons';
import { Col, Skeleton, Space } from 'antd';
import ReactMarkdown from 'react-markdown';
import { googleAnalyticsHelper } from '../../../../../helpers';

export const WarrantyDocuments = ({ content }) => {
  const onClickHandler = (url, label) => {
    googleAnalyticsHelper('pdf interaction', 'page change', label);

    window.open(url, '_blank');
  };

  return (
    <Col xs={24} lg={12} xl={9}>
      {content?.body ? (
        <ReactMarkdown source={content.body} />
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} />
      )}
      <Space direction={'vertical'}>
        {content?.warrantyReturnPolicyPDF &&
          content?.warrantyReturnPolicyLinkText && (
            <a
              onClick={() =>
                onClickHandler(
                  content?.warrantyReturnPolicyPDF,
                  'warranty policy'
                )
              }
            >
              <FileTextFilled style={{ color: 'black' }} />{' '}
              {content?.warrantyReturnPolicyLinkText}
            </a>
          )}

        {content?.warrantyEvaluationAidPDF &&
          content?.warrantyEvaluationAidLinkText && (
            <a
              onClick={() =>
                onClickHandler(
                  content?.warrantyEvaluationAidPDF,
                  'warranty evaluation aid'
                )
              }
            >
              <FileTextFilled style={{ color: 'black' }} />{' '}
              {content?.warrantyEvaluationAidLinkText}
            </a>
          )}
      </Space>
    </Col>
  );
};
